import request from './services/axios.js'
//邮件推送 -- 自主推送相关接口
//获取当前推送任务
export function GetCurrentTask(params) {
  return request({
    params,
    url: '/vas/push/task/getCurrentTask',
    method: 'get'
  })
}
//上传任务进度查询
export function ImportProcessCheck(params) {
  return request({
    params,
    url: '/vas/push/task/importProcessCheck',
    method: 'get'
  })
}
//获取任务文章列表
export function GetTaskArticles(params) {
  return request({
    params,
    url: '/getTaskArticles',
    method: 'get'
  })
}
//new获取任务文章列表
export function apiGetTaskArticles(params) {
  return request({
    params,
    url: '/vas/push/task/getTaskArticles',
    method: 'get'
  })
}
//自助推送试推送
export function TaskTrySend(params) {
  return request({
    params,
    url: '/vas/push/task/trySend',
    method: 'get'
  })
}
//自助推送
export function TaskSend(params) {
  return request({
    params,
    url: '/vas/push/task/send',
    method: 'get'
  })
}
//官网文章-根据任务id获取期刊刊期列表
export function GetIssuesByMagId(params) {
  return request({
    params,
    url: '/vas/mag/getIssuesByMagId',
    method: 'get'
  })
}
//邮件推送-官网文章-保存选中论文列表
export function SaveOfficialArticle(params) {
  return request({
    params,
    url: '/vas/push/task/saveOfficialArticle',
    method: 'post'
  })
}
//保存标题和微网刊标题
export function SaveTaskInfo(params) {
  return request({
    params,
    url: '/vas/push/task/saveTaskInfo',
    method: 'post'
  })
}
// 获取推送人数数量接口
export function GetSendMemberSize(params) {
  return request({
    params,
    url: '/vas/push/task/getSendMemberSize',
    method: 'get'
  })
}
// 根据论文id更新论文官网Url地址
export function UpdateArticleUrl(params) {
  return request({
    params,
    url: '/vas/article/updateArticleUrl',
    method: 'get'
  })
}
// 查询自定义导入文件进度
export function GetCustomTaskSchedule(params) {
  return request({
    params,
    url: '/vas/excelimporttask/getByKeyValue',
    method: 'get'
  })
}
// 选择邮件文章数据为微网刊
export function SetEmailArticles(params) {
  return request({
    params,
    url: '/vas/setEmailArticles',
    method: 'get'
  })
}
// 获取微网刊下的微文章列表
export function GetNetworkArticles(params) {
  return request({
    params,
    url: '/vas/getNetworkArticles',
    method: 'get'
  })
}
// 编辑微网刊
export function EditNetworkJournal(params) {
  return request({
    data: params,
    url: '/vas/editNetworkJournal',
    method: 'put'
  })
}
// 获取历史网刊列表数据
export function GetHistoryList(params) {
  return request({
    params,
    url: '/vas/networkjournal/listByMagId',
    method: 'get'
  })
}
// 获取每刊微网刊基本信息
export function GetJournalInfo(params) {
  return request({
    params,
    url: '/vas/getFirstJournal',
    method: 'get'
  })
}
// 获取每期微网刊基本信息
export function GetIssueInfo(params) {
  return request({
    params,
    url: '/vas/getNetworkJournalInfo',
    method: 'get'
  })
}
// 修改每期微网刊文章列表数据
export function UpdateIssueArticleList(params) {
  return request({
    data: params,
    url: '/vas/updateNetworkArticleInfo',
    method: 'put'
  })
}
// 获取期刊设置模板数据
export function GetTemplateList(params) {
  return request({
    params,
    url: '/vas/networktemplate/list',
    method: 'get'
  })
}
// 微网刊网刊历史网刊上移下移排序
export function UpOrBottom(params) {
  return request({
    params,
    url: '/vas/networkjournal/sequence',
    method: 'get'
  })
}
// 微网刊网刊历史网刊批量删除
export function batchDelete(params) {
  return request({
    params,
    url: '/vas/networkjournal/batchDelete',
    method: 'get'
  })
}
//微网刊推送-官网文章-保存选中论文列表
export function SaveOfficialArticleForWeChat(params) {
  return request({
    params,
    url: '/vas/push/task/saveOfficialArticleForWeChat',
    method: 'post'
  })
}
// 修改邮箱推送论文列表数据
export function UpdateEmailArticleList(params) {
  return request({
    data: params,
    url: '/vas/push/article/update',
    method: 'put'
  })
}
// 邮件推送-删除推送任务
export function DeleteTaskInfo(params) {
  return request({
    params,
    url: '/vas/push/task/deleteTaskInfo',
    method: 'get'
  })
}
// 微网刊文章-专题列表查询
export function GetSubjectList(params) {
  return request({
    params,
    url: '/vas/subject/listSubject',
    method: 'get'
  })
}
// 微网刊文章-根据专题名称查询专题论文
export function GetSubjectArticles(params) {
  return request({
    params,
    url: '/vas/mag/getSubjectArticles',
    method: 'get'
  })
}
// 邮件文章-优先论文查询
export function GetFirstArticles(params) {
  return request({
    params,
    url: '/vas/mag/getFirstArticles',
    method: 'get'
  })
}
// 推送论文推荐论文初始化状态查询
export function getInitRecommendArticleStatus(params) {
  return request({
    params,
    url: '/vas/push/task/getInitRecommendArticleStatus',
    method: 'get'
  })
}
// 推送论文推荐论文初始化状态查询
export function initPushRecommendArticle(params) {
  return request({
    params,
    url: '/vas/push/task/initPushRecommendArticle',
    method: 'get'
  })
}
// 样例文件下载
export const SampleDownload = PROJECT_CONFIG.BASE_URL + '/vas/push/task/importTemplateDownload'
// 样例文件下载
export const DownloadFailRecord = PROJECT_CONFIG.BASE_URL + '/vas/push/task/downloadFailRecord'
// 邮件推送文件上传
export const articleUploadUrl = PROJECT_CONFIG.BASE_URL + '/vas/push/task/importArticle'
// 官网文章下载
export const exportIssueArticles = PROJECT_CONFIG.BASE_URL + '/vas/mag/exportIssueArticles'
// 推送学者下载
export const exportSendMember = PROJECT_CONFIG.BASE_URL + '/vas/push/task/getSendMember'
// 自定义导入样例文件下载
export const DownloadByCode = PROJECT_CONFIG.BASE_URL + '/vas/template/downloadByCode'
// 自定义导入学者上传文件
export const AuthorUploadUrl = PROJECT_CONFIG.BASE_URL + '/vas/push/task/importAuthor'
// 自定义推送结果下载
export const DownloadByRecordId = PROJECT_CONFIG.BASE_URL + '/vas/common/downloadByRecordId'
// 下载失败记录
export const DownloadFailRecordByTaskValue = PROJECT_CONFIG.BASE_URL + '/vas/excelimporttask/getByKeyValue'
// 获取图片路径
export const GetPicturePath = PROJECT_CONFIG.BASE_URL + '/vas/push/configManage/getPicturePath'
// 上传微网刊数据
export const articleWeChatUploadUrl = PROJECT_CONFIG.BASE_URL + '/vas/push/task/importWeChatArticle'
// 每期导入学者下载样例
export const IssueExportSampleDownload = PROJECT_CONFIG.BASE_URL + '/vas/template/downloadByCode'
// 每期导入学者导入学者
export const IssueExportUploadUrl = PROJECT_CONFIG.BASE_URL + '/vas/push/task/importHistoryAuthor'
//邮件推送-微网刊文章导出
export const ArticleReport = PROJECT_CONFIG.BASE_URL + '/exportNetworkArticleList'
//邮件推送-微网刊文章导入
export const ArticleImport = PROJECT_CONFIG.BASE_URL + '/importArticle'
