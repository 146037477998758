<!-- scholarAnalysis -->
<template>
  <div class="w center_all scholarAnalysis">
    <div class="center_2" v-loading="loading" element-loading-text="正在加载，请稍后...">
      <div class="header">
        <ul class="crumbs clearfix">
          <li>邮件推送</li>
          <li>-></li>
          <li>任务管理</li>
          <li>-></li>
          <li>学者管理</li>
        </ul>
      </div>
      <div class="report-content" v-if="showMBYHSFLY">
        <div class="page-part">
          <div class="echarts-first-title">
            邮件传播——目标用户算法来源分布
            <div class="version-wrapper">
              推送版本
              <el-select clearable v-model="selectedDateVal" @change="changeVersion">
                <el-option v-for="(item, idx) in sendVersionOptions" :key="idx" :label="item.date"
                  :value="item.value"></el-option>
              </el-select>
            </div>
          </div>
          <div class="echarts-left-title mb-20">
            目标用户<span>{{ userDataObj.countPushMagAuthor || 0 }}人</span>
          </div>
          <SimpleTable ref="MBYHLYNewTable" no-pagination noSerial showBorder :table-data="MBYHLYNewTableData"
            :column-data="MBYHLYNewColumnData" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  GetPushMagAuthorNumber,
  GetPushMagAuthorSetCount,
  getSendVersion
} from '@/api/scholarAnalysis'
import SimpleTable from '@/components/SimpleTable'
import { getRecentYears } from '@/utils'
export default {
  name: 'scholarAnalysis',
  data() {
    return {
      loading: true,
      noImg: require('@/assets/img/default.png'),
      downloadReportImg: require('@/assets/img/download-report.png'),
      downloadReportDisabledImg: require('@/assets/img/download-report-disabled.png'),
      isDisabled: false,
      basicInfo: {},
      currentTask: {},
      JGFWTJTableData: [],
      JGFWTJColumnFive: [
        {
          title: '发文机构',
          prop: 'institutionName',
          width: '150px',
          notSortable: true
        },
        ...getRecentYears(5, 'prop', 'title', '', '', 'notSortable'),
        {
          title: '合计',
          prop: 'totalCount5',
          notSortable: true
        },
        {
          title: '平均被引',
          prop: 'referedCount5',
          notSortable: true
        },
        {
          title: '单篇最高被引',
          width: '130px',
          prop: 'singleReferedCount5',
          notSortable: true
        }
      ],
      YYJGTableData: [],
      YYJGColumnFive: [
        {
          title: '引用机构',
          prop: 'institutionName',
          width: '190px',
          notSortable: true
        },
        ...getRecentYears(5, 'prop', 'title', '', '', 'notSortable'),
        {
          title: '合计',
          prop: 'totalCount5',
          notSortable: true
        }
      ],
      YZJGTableData: [],
      YZJGColumnFive: [
        {
          title: '引证机构',
          prop: 'institutionName',
          width: '190px',
          notSortable: true
        },
        ...getRecentYears(5, 'prop', 'title', '', '', 'notSortable'),
        {
          title: '合计',
          prop: 'totalCount5',
          notSortable: true
        }
      ],
      MBYHLYColumnData: [
        {
          title: '来源',
          notSortable: true,
          prop: 'source',
          width: '50px'
        }
      ],
      MBYHLYTableData: [],
      MBYHLYNewColumnData: [
        {
          title: '学者来源',
          notSortable: true,
          prop: '学者来源'
        },
        {
          title: '论文范围',
          notSortable: true,
          prop: '论文范围'
        },
        {
          title: '学者行为',
          notSortable: true,
          prop: '学者行为'
        },
        {
          title: '学者特征',
          notSortable: true,
          prop: '学者特征'
        },
        {
          title: '人数',
          notSortable: true,
          prop: '人数'
        }
      ],
      MBYHLYNewTableData: [],
      userDataObj: {},
      issueColumnList: [],
      showMBYHSFLY: true,
      selectedDateVal: '',
      sendVersionOptions: []
    }
  },
  props: {},
  components: { SimpleTable },
  computed: {
    ...mapGetters({
      magId: 'magId'
    })
  },
  mounted() {
    this.currentTask = JSON.parse(sessionStorage.getItem('emailCurrentTask'))
    window.addEventListener('resize', this.adjustTable)
    this.$nextTick(() => {
      if (this.magId) {
        this.getData()
      }
      this.getSendVersion()
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.adjustTable)
  },
  methods: {
    // 窗口大小改变重新渲染echarts
    adjustTable() {
      if (document.getElementById('chartYYQK')) {
        this.$echarts.init(document.getElementById('chartYYQK')).resize()
      }
      if (document.getElementById('chartYZQK')) {
        this.$echarts.init(document.getElementById('chartYZQK')).resize()
      }
      if (document.getElementById('chartMBYHJG')) {
        this.$echarts.init(document.getElementById('chartMBYHJG')).resize()
      }
      if (document.getElementById('chartMBYHQK')) {
        this.$echarts.init(document.getElementById('chartMBYHQK')).resize()
      }
    },
    // 计算表格内容高度使其居中
    getTableContentHeight(dom, haveLeftTitle, isEcharts) {
      this.$nextTick(() => {
        let computedHeight = 0
        let childrenList = document.getElementsByClassName(dom)[0].children
        for (var i = 0; i < childrenList.length; i++) {
          if (isEcharts) {
            if (childrenList[i].className) {
              computedHeight = computedHeight + childrenList[i].clientHeight
            }
          } else {
            if (childrenList[i].className !== 'table-content') {
              computedHeight = computedHeight + childrenList[i].clientHeight
            }
          }
        }
        let fanalHeight = 525 - computedHeight - (haveLeftTitle ? 20 : 0)
        for (var j = 0; j < childrenList.length; j++) {
          if (isEcharts) {
            if (!childrenList[j].className) {
              childrenList[j].style.height = fanalHeight + 'px'
            }
          } else {
            if (childrenList[j].className === 'table-content') {
              childrenList[j].style.height = fanalHeight + 'px'
            }
          }
        }
      })
    },
    getData(versionUUid) {
      Promise.all([
        this.loading = false,
        this.getPushMagAuthorNumber(versionUUid),
        this.getPushMagAuthorSetCount(versionUUid),
      ]).then(() => {
        this.isDisabled = false
        this.loading = false
      })
    },
    getPushMagAuthorNumber(versionUUid) {
      const params = {
        historyId: this.currentTask.id,
        sendEmailUuid: versionUUid || this.currentTask.sendEmailUuid || ''
      }
      return new Promise((resolve, reject) => {
        GetPushMagAuthorNumber(params).then((res) => {
          if (res && res.data && res.data.status === 0) {
            const { data } = res.data
            this.userDataObj = data
            resolve()
          } else {
            const { message } = res.data
            this.$message.error(message)
            reject()
          }
        })
      })
    },
    //目标用户来源分布
    getPushMagAuthorSetCount(versionUUid, pageParams = {}) {
      const { page = 1, size = 10 } = pageParams
      const params = {
        historyId: this.currentTask.id,
        page: page - 1,
        size,
        sendEmailUuid: versionUUid || this.currentTask.sendEmailUuid
      }
      return new Promise((resolve, reject) => {
        GetPushMagAuthorSetCount(params).then((res) => {
          if (res && res.data && res.data.status === 0) {
            const { data } = res.data
            this.MBYHLYNewTableData = data
            resolve()
          } else {
            const { message } = res.data
            this.$message.error(message)
            reject()
          }
        })
      })
    },
    formatTimestamp(timestamp) {
      const date = new Date(parseInt(timestamp, 10))
      if (isNaN(date.getTime())) {
        return ' '
      }
      return date.toLocaleDateString('zh-CN', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      })
    },
    changeVersion(val) {
      this.getData(val)
    },
    getSendVersion() {
      return new Promise((resolve, reject) => {
        getSendVersion({ taskId: this.currentTask.id }).then((res) => {
          let { data, status, message } = res.data
          if (status) {
            this.$message.error(message)
            reject()
          } else {
            this.sendVersionOptions = data.map(_ => ({ value: _, date: this.formatTimestamp(_) }))
            resolve()
          }
        })
      })
    }
  }
}
</script>
<style lang="scss">
.scholarAnalysis {
  .el-loading-spinner {
    top: 2%;
  }

  .table-content {
    .el-table {
      margin-top: 20px !important;
      overflow-y: hidden !important;

      tr {
        height: 30px !important;
      }

      th,
      td {
        height: 30px !important;
        padding: 0 !important;

        .cell {
          height: 30px !important;
          line-height: 30px !important;
        }
      }

      .el-table__header-wrapper th .cell {
        color: #fff !important;
      }

      th {
        background-color: #4054af !important;
      }
    }
  }

  .el-table--striped .el-table__body tr.el-table__row--striped td {
    background-color: #d8ddf6 !important;
  }
}
</style>
<style lang="scss" scoped>
.scholarAnalysis {
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .download-btn {
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    color: #4054af;
    display: flex;
    line-height: 27px;
    justify-content: flex-end;
    margin: 30px;
    position: relative;
    bottom: -440px;
    z-index: 99;

    img {
      width: 30px;
      height: 30px;
      margin-right: 6px;
    }
  }

  .download-btn-disabled {
    color: #c0c4cc;
    cursor: no-drop;
  }

  .report-content {
    width: 1040px;
    margin: auto;

    .page-part {
      position: relative;
      border-bottom: 0;
      padding: 30px;
      height: 525px;

      .table-content {
        display: flex;
        align-items: center;

        .table-content-box {
          margin: auto;
          width: 100%;
        }
      }

      .position-bottom {
        padding-top: 20px;

        .echarts-line {
          display: flex;
          align-items: center;
          justify-content: center;

          .line1 {
            width: 50%;
            height: 2px;
            background: #c6c3c3;
          }

          .line2 {
            width: 5px;
            height: 5px;
            border-radius: 50%;
            margin: 0 8px;
            background: #c6c3c3;
          }
        }

        .bottom-info {
          display: flex;
          font-size: 12px;
          color: #929090;
          margin-top: 20px;
          line-height: 16px;
          text-align: left;

          .bottom-info-left {
            width: 50%;
            text-align: left;
          }

          .bottom-info-right {
            width: 50%;
            text-align: justify;
          }
        }
      }

      .echarts-first-title {
        display: flex;
        justify-content: space-between;
        color: #4054af;
        font-size: 18px;
        font-weight: bold;
        text-align: left;
      }

      .echarts-second-title {
        text-align: center;
      }

      .echarts-left-title {
        margin-top: 20px;
        text-align: left;
        font-size: 16px;

        span {
          color: #4054af;
        }
      }

      .version-wrapper {
        font-size: 14px;
      }
    }

    .flex-content {
      display: flex;
    }

    .page1 {
      align-items: center;
      justify-content: center;

      .title {
        font-size: 24px;
        font-weight: 600;
        color: #4054af;
        letter-spacing: 6px;
        position: relative;
        top: 200px;
      }
    }

    .page7 {
      .situation {
        display: flex;
        align-items: center;
        justify-content: space-around;
        height: 97%;

        .situation-info {
          width: 45%;
          text-align: center;

          .title {
            font-size: 16px;
            font-weight: bold;
          }

          .info {
            margin-top: 6%;

            .text-box {
              width: 34%;
              display: inline-block;
              border: 1px solid rgb(47, 133, 204);
              font-size: 14px;
              border-radius: 10px;
              padding: 6% 10px;

              span {
                display: block;
                color: #4054af;
                font-size: 20px;
                margin-top: 6px;
              }
            }

            .ml-percent16 {
              margin-left: 12%;
            }

            .mt-percent16 {
              margin-top: 12%;
            }
          }
        }
      }
    }
  }
}
</style>
