<template>
  <div class="task-list" :class="taskListData.length > 0 ? '' : 'mt-40'">
    <SimpleTable
      ref="taskList"
      noSerial
      showChoose
      showListBtn
      showOperate
      showBtn1
      showBtn2
      isDisabledClick
      maxHeight="440px"
      btn1Text="扩展推送"
      btn2Text="删除"
      :tableData="taskListData"
      :columnData="taskListColumn"
      :initObj="taskListPageObj"
      :contentData="taskListContentData"
      @onRowClick="handleRowClick"
      @onClickBtn1="pushAgain"
      @onClickBtn2="deleteTaskInfo"
      @onChangeInputBlur="handleTaskChangeInputBlur"
      @onChangePageData="
        (pageObj) => {
          getTaskList(pageObj)
        }
      "
    />
    <!-- 删除弹框 -->
    <el-dialog :visible.sync="deleteDialogVisible" width="300px" :show-close="true">
      <p>确定删除吗</p>
      <span slot="footer" class="dialog-footer">
        <el-button @click="deleteDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="deleteItems">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 推送弹框 -->
    <pushDialog ref="pushDialog" @pushSuccess="pushSuccess"></pushDialog>
  </div>
</template>

<script>
import SimpleTable from '@/components/SimpleTable'
import pushDialog from './pushDialog'
import { GetPushTaskList, DeleteTaskInfo, NewEditTaskInfo } from '@/api/emailPush'
import { GetTaskInfo } from '@/api/publishResults'
import { mapGetters } from 'vuex'
export default {
  name: 'taskList',
  components: {
    SimpleTable,
    pushDialog
  },
  computed: {
    ...mapGetters({
      platMagId: 'platMagId',
      platTenantId: 'platTenantId',
      magId: 'magId',
      platMagName: 'platMagName'
    })
  },
  props: {
    chooseTaskId: {
      type: String,
      default: ''
    },
    taskType: {
      default: ''
    }
  },
  data() {
    return {
      taskListData: [],
      taskListColumn: [
        {
          title: '推送标题',
          prop: 'topic',
          notSortable: true,
          special: true,
          width: '300px'
        },
        {
          title: '内容类型',
          prop: 'typeText',
          notSortable: true,
          width: '80px'
        },
        {
          title: '年份',
          prop: 'year',
          notSortable: true,
          width: '50px',
          edit: false
        },
        {
          title: '刊期',
          prop: 'issue',
          notSortable: true,
          width: '50px',
          edit: false
        },
        {
          title: '推送时间',
          prop: 'pushTime',
          notSortable: true
        },
        {
          title: '推送人',
          prop: 'operatorName',
          notSortable: true,
          width: '70px'
        },
        {
          title: '推送范围',
          prop: 'pushAuthorCount',
          notSortable: true,
          width: '80px'
        },
        {
          title: '已推送数',
          prop: 'pushSuccessCount',
          notSortable: true,
          width: '80px'
        },
        {
          title: '打开邮件次数',
          prop: 'emailOPenCount',
          notSortable: true,
          width: '100px'
        },
        {
          title: '打开链接次数',
          prop: 'readCount',
          notSortable: true,
          width: '100px'
        },
        {
          title: '今日状态',
          prop: 'status',
          notSortable: true,
          width: '80px'
        },
        {
          title: '推送完成日期',
          prop: 'pushEndTime',
          notSortable: true,
          width: '100px'
        }
      ],
      taskListPageObj: {
        page: 1,
        size: 10
      },
      taskListContentData: {},
      dialogPushVisible: false,
      pushItem: {},
      userNumber: null,
      deleteDialogVisible: false,
      chooseItem: ''
    }
  },
  watch: {
    magId() {
      this.getTaskList()
      this.setTopicColumnWidth()
    },
    chooseItem(n) {
      // 专题年期可编辑
      if (n) {
        if (this.chooseItem.type == 2) {
          this.taskListColumn[2].edit = true
          this.taskListColumn[3].edit = true
        } else {
          this.taskListColumn[2].edit = false
          this.taskListColumn[3].edit = false
        }
      }
    }
  },
  mounted() {
    if (this.magId) {
      this.getTaskList()
      this.setTopicColumnWidth()
    }
  },
  methods: {
    // 获取任务列表数据
    getTaskList(pageParams = {}, type, item) {
      let { page = 1, size = 10 } = pageParams
      let params = {
        magId: this.magId,
        page: page - 1,
        size,
        tenantId: this.platTenantId,
        publicationId: this.platMagId,
        status: 0 + ',' + 1 + ',' + 2 + ',' + 3,
        type: this.taskType || ''
      }
      GetPushTaskList(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          const { data } = res.data
          this.taskListPageObj = {
            ...this.taskListPageObj,
            page,
            size,
            total: data.totalElements
          }
          this.taskListContentData = data
          this.taskListData = data.content
          this.$emit('input', this.taskListData)
          if (this.taskListData.length > 0) {
            this.taskListData.map((it, index) => {
              //type 1:整期 2:专题 3:通知 4:单篇
              if (it.type == 1) {
                it.typeText = '整期'
              } else if (it.type == 2) {
                it.typeText = '专题'
              } else if (it.type == 3) {
                it.typeText = '通知'
              } else if (it.type == 4) {
                it.typeText = '单篇'
              }
              //status 0:待发送 1:推送中 2:已试推送 3: 已推送
              it.status = it.status === 1 ? '推送中' : it.status === 2 ? '已试推送' : it.status === 3 ? '已推送' : '待发送'
              it.activeBtn1 = it.status === '已推送'
              it.activeBtn2 = it.status === '待发送'
              if (item && item.id === it.id) {
                item = { ...it }
              }
            })
            this.$nextTick(() => {
              if (type === 'edit' || type === 'isPush') {
                this.$refs.taskList.handleRowClick(item)
              } else {
                this.$refs.taskList.handleRowClick(this.taskListData[0])
              }
            })
          } else {
            this.$refs.taskList.initData()
            this.handleRowClick('')
          }
        } else {
          const { message = '' } = res.data
          this.$message.error(message)
        }
      })
    },
    // 点击行
    handleRowClick(chooseId, item) {
      this.chooseItem = item
      this.$emit('getCurrentTask', chooseId, item)
    },
    //任务列表任务为专题时支持年期修改
    handleTaskChangeInputBlur(val, prop, event, index, content) {
      let params = {
        magId: this.magId,
        topic: this.chooseItem.topic,
        operatorName: '方正运营',
        taskId: this.taskListData[index].id,
        type: this.chooseItem.type,
        year: prop === 'year' ? content : this.taskListData[index].year,
        issue: prop === 'issue' ? content : this.taskListData[index].issue
      }
      NewEditTaskInfo(params).then((resp) => {
        let { data, status } = resp.data
        if (status !== 0) {
          this.$message({
            type: 'error',
            message: data.message
          })
        } else {
          this.$message({
            type: 'success',
            message: '修改成功!'
          })
          this.getTaskList(this.taskListPageObj, 'edit', this.taskListData[index])
        }
      })
    },
    // 删除推送任务弹框
    deleteTaskInfo(item) {
      this.deleteDialogVisible = true
    },
    // 删除推送任务确定
    deleteItems(item) {
      let params = {
        taskId: this.chooseItem.id,
        operator: sessionStorage.getItem('realName')
      }
      DeleteTaskInfo(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
          this.deleteDialogVisible = false
          this.getTaskList()
        } else {
          const { message = '' } = res.data
          this.$message.error(message)
        }
      })
    },
    // 扩展推送
    pushAgain(item, type) {
      let taskType = type ? type : item.pushType // 列表扩展推送没传type默认传item.pushType
      const params = {
        taskId: item.id
      }
      GetTaskInfo(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          const { data } = res.data
          this.$nextTick(() => {
            this.$refs.pushDialog.dialogPushVisible = true
            this.$refs.pushDialog.getSendMemberSize(data, taskType, this.taskListPageObj)
          })
        } else {
          const { message = '' } = res.data
          this.$message.error(message)
        }
      })
    },
    // 推送成功后
    pushSuccess(obj, item) {
      this.getTaskList(obj, 'isPush')
      this.$nextTick(() => {
        this.$refs.taskList.handleRowClick(item)
      })
    },
    setTopicColumnWidth() {
      const width = this.platMagName.length * 20 + 100;
      this.taskListColumn[0].width = `${width}px`;
    }
  }
}
</script>

<style lang="scss">
.task-list {
  .delete,
  .up-bottom-btns {
    float: left;
  }
}
</style>
<style lang="scss" scoped>
.mt-40 {
  margin-top: 40px;
}
</style>
