<!-- previewPushResultsList -->
<template>
  <div class="previewPushResultsList w center_all">
    <div class="center_2">
      <h3>推送数据一览</h3>
      <div class="options">
        <div class="options-item">
          <span>期刊：</span>
          <el-select v-model="chooseMagId" clearable filterable placeholder="请选择" @change="magIdChange">
            <el-option v-for="item in magList" :key="item.magId" :label="item.magName" :value="item.magId" />
          </el-select>
        </div>
        <div class="options-item">
          <span>类型：</span>
          <el-select v-model="chooseType" clearable filterable placeholder="请选择">
            <el-option v-for="item in typeList" :key="item.key" :label="item.key" :value="item.value" />
          </el-select>
        </div>
        <div class="options-item">
          <span>推送时间：</span>
          <el-date-picker v-model="chooseDate" type="daterange" align="right" unlink-panels range-separator="至"
            start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions" @change="chooseDateChange">
          </el-date-picker>
        </div>
        <el-button @click="getTableList">查询</el-button>
        <el-button @click="exportExcel" :disabled="tableData.length === 0">导出</el-button>
      </div>
      <div class="table-list">
        <SimpleTable noSerial showOperate showBtn1 :showBtn2="false" btn1Text="详情" :tableData="tableData"
          :columnData="tableColumn" :initObj="tablePageObj" @onClickBtn1="handleBtn1Click" @onChangeSort="(prop, order) => {
            handleChangeSort(prop, order)
          }
            " @onChangePageData="(pageObj) => {
              getTableList(pageObj)
            }
              "></SimpleTable>
      </div>
    </div>
  </div>
</template>

<script>
import SimpleTable from '@/components/SimpleTable'
import { PushTaskList, GetPushMag, ExportTaskInfo } from '@/api/roleManagement'
import { FormatDateTime } from '@/assets/js/util'
export default {
  name: 'previewPushResultsList',
  data() {
    return {
      magList: [],
      typeList: [{ 'key': '整期', 'value': 1 }, { 'key': '专题', 'value': 2 }, { 'key': '通知', 'value': 3 }, { 'key': '单篇', 'value': 4 }],
      chooseMagId: '',
      chooseType: '',
      chooseDate: [],
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            }
          }
        ]
      },
      tableData: [],
      tableColumn: [
        {
          title: '序号',
          prop: 'taskOrder',
          notSortable: true,
          width: '60px',
          fixed: true
        },
        {
          title: '任务名称',
          prop: 'topic',
          notSortable: true,
          width: '350px',
          fixed: true
        },
        {
          title: '推送数量',
          prop: 'pushAuthorCount',
          width: '100px'
        },
        {
          title: '推送成功数量',
          prop: 'pushSuccessCount',
          width: '125px'
        },
        {
          title: '总打开数',
          prop: 'emailOPenCount',
          width: '100px'
        },
        {
          title: '唯一打开数',
          prop: 'onlyEmailOPenCount',
          width: '110px'
        },
        {
          title: '打开率',
          prop: 'emailOPenPercent',
          width: '90px'
        },
        {
          title: '点击数',
          prop: 'readCount',
          width: '90px'
        },
        {
          title: '唯一点击数',
          prop: 'onlyReadCount',
          width: '110px'
        },
        {
          title: '点击率',
          prop: 'articleClickPercent',
          width: '90px'
        },
        {
          title: '推送时间',
          prop: 'pushTime',
          width: '150px'
        },
        {
          title: '推送类型',
          prop: 'typeText',
          notSortable: true,
          width: '100px'
        },
        {
          title: '学者类型',
          prop: 'pushTypeText',
          notSortable: true,
          width: '120px'
        },
        {
          title: '刊名',
          prop: 'magName',
          notSortable: true,
          width: '200px'
        },
        {
          title: '年份',
          prop: 'year',
          notSortable: true,
          width: '80px'
        },
        {
          title: '刊期',
          prop: 'issue',
          notSortable: true,
          width: '80px'
        }
      ],
      tablePageObj: {
        page: 1,
        size: 10,
        sort: 'pushTime,desc'
      }
    }
  },
  props: {},
  components: { SimpleTable },
  computed: {},
  mounted() {
    this.getMagList()
    this.getTableList()
  },
  methods: {
    // 跳转至详情
    handleBtn1Click(item) {
      let routeData = this.$router.resolve({
        name: 'previewPushResultsDetails',
        query: {
          topic: item.topic,
          id: item.id
        }
      })
      window.open(routeData.href, '_blank')
    },
    // 获取期刊列表
    getMagList() {
      let params = {}
      GetPushMag(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          const { data } = res.data
          this.magList = data
        } else {
          const { message = '' } = res.data
          this.$message.error(message)
        }
      })
    },
    // 获取列表
    getTableList(pageParams = {}) {
      let { page = 1, size = 10 } = pageParams
      let params = {
        page: page - 1,
        size,
        sort: this.tablePageObj.sort,
        status: 3,
        magId: this.chooseMagId,
        type: this.chooseType,
        startPushTime: this.chooseDate[0] || '',
        endPushTime: this.chooseDate[1] || ''
      }
      PushTaskList(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          const { data } = res.data
          // 为每个数据项计算序号
          this.tableData = data.content.map((item, index) => ({
            ...item,
            taskOrder: index + 1 + ((page - 1) * size)
          }));
          this.tablePageObj = {
            ...this.tablePageObj,
            page,
            size,
            total: data.totalElements
          }
          if (this.tableData.length > 0) {
            this.tableData.map((item) => {
              if (item.pushType === 1) {
                item.pushTypeText = '刊维度学者'
              } else if (item.pushType === 4) {
                item.pushTypeText = '刊维度激活学者'
              } else if (item.pushType === 5) {
                item.pushTypeText = '期维度学者'
              } else if (item.pushType === 6) {
                item.pushTypeText = '期维度激活学者'
              } else if (item.pushType === 7) {
                item.pushTypeText = '导入学者'
              } else if (item.pushType === 10) {
                item.pushTypeText = '自定义推送学者'
              } else {
                item.pushTypeText = '智能推荐'
              }

              if (item.type === 1) {
                item.typeText = '整期'
              } else if (item.type === 2) {
                item.typeText = '专辑'
              } else if (item.type === 3) {
                item.typeText = '通知'
              } else if (item.type === 4) {
                item.typeText = '单篇'
              } else {
                item.typeText = '未知'
              }
            })
          }
        } else {
          const { message = '' } = res.data
          this.$message.error(message)
        }
      })
    },
    // 导出列表
    exportExcel() {
      window.open(
        `${ExportTaskInfo}?magId=${this.chooseMagId}&type=${this.chooseType}&page=0&size=9999&sort=${this.tablePageObj.sort}&status=3&startPushTime=${this.chooseDate[0] || ''}&endPushTime=${this.chooseDate[1] || ''}`,
        '_self'
      )
    },
    // 选中时间改变
    chooseDateChange() {
      this.chooseDate[0] = FormatDateTime(this.chooseDate[0])
      this.chooseDate[1] = FormatDateTime(this.chooseDate[1])
      // this.getTableList()
    },
    magIdChange() {
      // this.getTableList()
    },
    // 排序的改变了
    handleChangeSort(prop, order) {
      let sortValue = ''
      if (order && order !== '') {
        if (order === 'ascending') {
          sortValue = prop + ',asc'
        } else {
          sortValue = prop + ',desc'
        }
      }
      this.tablePageObj.sort = sortValue
      this.getTableList()
    }
  }
}
</script>

<style lang="scss" scoped>
.previewPushResultsList {
  .center_2 {
    min-height: 400px;
    border-radius: 8px;
    padding: 40px;

    h3 {
      text-align: left;
    }

    .options {
      display: flex;
      margin-top: 30px;

      .options-item {
        margin-right: 20px;
      }
    }

    .table-list {
      margin-top: 30px;
    }
  }
}
</style>
