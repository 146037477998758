import Vue from "vue";
import Router from "vue-router";
Vue.use(Router);
// 0:普通  1:超级管理员 2:管理员  3:销售  4:设计 5:服务 6:采集 7:数据中台 8:资源中心 9:产品（管理）
let allRolesPage = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
let salePage = [3]; // 销售权限
let designerPage = [4]; // 设计师权限
let productPage = [1, 2]; // 产品权限
export default new Router({
  routes: [
    {
      // 登录
      path: "/",
      name: "Login",
      component: () => import(/* webpackChunkName:"login" */ "@/views/Login"),
      meta: { requireAuth: false, roles: allRolesPage }
    },
    {
      // 采集任务平台
      path: "/collection",
      name: "Collection",
      component: () =>
        import(/* webpackChunkName:"Analysis" */ "@/views/Collection"),
      meta: { requireAuth: true, roles: allRolesPage }
    },
    {
      // 采集任务平台-新建
      path: "/add",
      name: "Add",
      component: () =>
        import(/* webpackChunkName:"Analysis" */ "@/views/Collection/component/add"),
      meta: { requireAuth: true, roles: allRolesPage }
    },
    {
      // 采集任务平台-编辑
      path: "/edit",
      name: "Edit",
      component: () =>
        import(/* webpackChunkName:"Analysis" */ "@/views/Collection/component/add"),
      meta: { requireAuth: true, roles: allRolesPage }
    },
    {
      // 采集任务平台-详情
      path: "/view",
      name: "View",
      component: () =>
        import(/* webpackChunkName:"Analysis" */ "@/views/Collection/component/view"),
      meta: { requireAuth: true, roles: allRolesPage }
    },
    {
      // 采集任务平台-日志
      path: "/log",
      name: "Log",
      component: () =>
        import(/* webpackChunkName:"Analysis" */ "@/views/Collection/component/log"),
      meta: { requireAuth: true, roles: allRolesPage }
    },
    {
      // 采集任务平台-统计
      path: "/statistics",
      name: "Statistics",
      component: () =>
        import(/* webpackChunkName:"Analysis" */ "@/views/Collection/component/statistics"),
      meta: { requireAuth: true, roles: allRolesPage }
    },
    {
      // 采集任务平台-统计-比对
      path: "/comparison",
      name: "Comparison",
      component: () =>
        import(/* webpackChunkName:"Analysis" */ "@/views/Collection/component/comparison"),
      meta: { requireAuth: true, roles: allRolesPage }
    },
    {
      // 领域分析
      path: "/analysis",
      name: "Analysis",
      component: () =>
        import(/* webpackChunkName:"Analysis" */ "@/views/Analysis"),
      meta: { requireAuth: true, roles: allRolesPage }
    },
    {
      // 内容聚合
      path: "/aggregation",
      name: "Aggregation",
      component: () =>
        import(/* webpackChunkName:"Analysis" */ "@/views/Aggregation"),
      meta: { requireAuth: true, roles: allRolesPage }
    },
    {
      // 内容聚合范围设置
      path: "/rangeSetting",
      name: "RangeSetting",
      component: () =>
        import(
          /* webpackChunkName:"Analysis" */ "@/views/Aggregation/rangeSetting"
        ),
      meta: { requireAuth: true, roles: allRolesPage }
    },
    {
      // 论文引用情况
      path: "/quote",
      name: "Quote",
      component: () => import(/* webpackChunkName:"Quote" */ "@/views/Quote"),
      meta: { requireAuth: true, roles: allRolesPage }
    },
    {
      // 期刊详情
      path: "/details",
      name: "Details",
      component: () =>
        import(/* webpackChunkName:"Details" */ "@/views/Details"),
      meta: { requireAuth: true, roles: allRolesPage }
    },
    {
      // 聚类详情
      path: "/clusterDetails",
      name: "ClusterDetails",
      component: () =>
        import(/* webpackChunkName:"Details" */ "@/views/ClusterDetails"),
      meta: { requireAuth: true, roles: allRolesPage }
    },
    {
      // 聚类详情
      path: "/articleDetails",
      name: "ArticleDetails",
      component: () => import(/* webpackChunkName:"Details" */ "@/views/Quote"),
      meta: { requireAuth: true, roles: allRolesPage }
    },
    {
      // 专属推送结果
      path: "/specialPushResults",
      name: "specialPushResults",
      component: () =>
        import(/* webpackChunkName:"Details" */ "@/views/SpecialPushResults"),
      meta: { requireAuth: true, roles: allRolesPage }
    },
    {
      // 资讯聚合
      path: "/informationAggregation",
      name: "InformationAggregation",
      component: () =>
        import(
          /* webpackChunkName:"Details" */ "@/views/InformationAggregation"
        ),
      meta: { requireAuth: true, roles: allRolesPage }
    },
    {
      // 资讯聚合范围设置
      path: "/informationRangeSetting",
      name: "informationRangeSetting",
      component: () =>
        import(
          /* webpackChunkName:"Details" */ "@/views/InformationAggregation/informationRangeSetting"
        ),
      meta: { requireAuth: true, roles: allRolesPage }
    },
    {
      // 资讯聚合配置管理
      path: "/informationSetting",
      name: "informationSetting",
      component: () =>
        import(
          /* webpackChunkName:"Details" */ "@/views/InformationAggregation/informationSetting"
        ),
      meta: { requireAuth: true, roles: allRolesPage }
    },
    // 邮件推送-推送结果
    {
      path: "/pushResults",
      name: "pushResults",
      component: () =>
        import(
          /* webpackChunkName:"PushResults" */ "@/views/EmailPush/pushResults"
        ),
      meta: { requireAuth: true, roles: allRolesPage }
    },
    // 邮件推送-任务管理
    {
      path: "/emailTaskManagement",
      name: "emailTaskManagement",
      component: () =>
        import(
          /* webpackChunkName:"SelfPush" */ "@/views/EmailPush/taskManagement/index"
        ),
      meta: { requireAuth: true, roles: allRolesPage }
    },
    // 邮件推送-学者分析
    {
      path: "/scholarAnalysis",
      name: "scholarAnalysis",
      component: () =>
        import(
          /* webpackChunkName:"SelfPush" */ "@/views/EmailPush/scholarAnalysis"
        ),
      meta: { requireAuth: true, roles: allRolesPage }
    },
    // 邮件推送-学者分析报告
    {
      path: "/scholarAnalysisReport",
      name: "scholarAnalysisReport",
      component: () =>
        import(
              /* webpackChunkName:"SelfPush" */ "@/views/EmailPush/scholarAnalysisReport"
        ),
      meta: { requireAuth: true, roles: allRolesPage }
    },
    // 邮件推送-选择学者领域
    {
      path: "/scholarMag",
      name: "scholarMag",
      component: () =>
        import(
          /* webpackChunkName:"SelfPush" */ "@/views/EmailPush/taskManagement/scholarMag"
        ),
      meta: { requireAuth: true, roles: allRolesPage }
    },
    // 邮件推送-内容配置
    {
      path: "/contentConfigure",
      name: "contentConfigure",
      component: () =>
        import(
          /* webpackChunkName:"SelfPush" */ "@/views/EmailPush/taskManagement/contentConfigure"
        ),
      meta: { requireAuth: true, roles: allRolesPage }
    },
    // 邮件推送-邮件预览
    {
      path: "/emailPreviewHtml",
      name: "emailPreviewHtml",
      component: () =>
        import("@/views/EmailPush/taskManagement/emailPreviewHtml"),
      meta: { requireAuth: true, roles: allRolesPage }
    },
    // 邮件推送-白名单管理
    {
      path: "/whitelist",
      name: "whitelist",
      component: () =>
        import(
          /* webpackChunkName:"Whitelist" */ "@/views/EmailPush/whitelist"
        ),
      meta: { requireAuth: true, roles: allRolesPage }
    },
    // 邮件推送-配置管理
    {
      path: "/configure",
      name: "configure",
      component: () =>
        import(
          /* webpackChunkName:"Configure" */ "@/views/EmailPush/settings/configure"
        ),
      meta: { requireAuth: true, roles: allRolesPage }
    },
    // 邮件推送-本刊模板
    {
      path: "/emailTemplate",
      name: "emailTemplate",
      component: () =>
        import(
          /* webpackChunkName:"emailTemplate" */ "@/views/EmailPush/settings/emailTemplate"
        ),
      meta: { requireAuth: true, roles: allRolesPage }
    },
    // 邮件推送-发件邮箱管理
    {
      path: "/outgoingMailboxManagement",
      name: "outgoingMailboxManagement",
      component: () =>
        import(
          /* webpackChunkName:"outgoingMailboxManagement" */ "@/views/EmailPush/outgoingMailboxManagement"
        ),
      meta: { requireAuth: true, roles: allRolesPage }
    },
    // 邮件推送-学者数据测评
    {
      path: "/scholarDataEvaluation",
      name: "scholarDataEvaluation",
      component: () =>
        import(
          /* webpackChunkName:"scholarDataEvaluation" */ "@/views/EmailPush/scholarDataEvaluation"
        ),
      meta: { requireAuth: true, roles: allRolesPage }
    },
    // 邮件推送-年度报告
    {
      path: "/annualReport",
      name: "annualReport",
      component: () =>
        import(
          /* webpackChunkName:"annualReport" */ "@/views/EmailPush/annualReport"
        ),
      meta: { requireAuth: true, roles: allRolesPage }
    },
    // 邮件推送-引证推送-列表页
    {
      path: "/citationPush",
      name: "citationPush",
      component: () => import("@/views/EmailPush/citationPush"),
      meta: { requireAuth: true, roles: allRolesPage }
    },
    // 邮件推送-引证推送-详情页
    {
      path: "/citationPushDetails",
      name: "citationPushDetails",
      component: () => import("@/views/EmailPush/citationPushDetails"),
      meta: { requireAuth: true, roles: allRolesPage }
    },
    // 邮件推送-引证推送-详情页
    {
      path: "/citationPushConfig",
      name: "citationPushConfig",
      component: () => import("@/views/EmailPush/citationPushConfig"),
      meta: { requireAuth: true, roles: allRolesPage }
    },
    // 邮件推送-引证推送-预览页
    {
      path: "/citationPushPreviewHtml",
      name: "citationPushPreviewHtml",
      component: () => import("@/views/EmailPush/citationPushPreviewHtml"),
      meta: { requireAuth: true, roles: allRolesPage }
    },
    // 邮件推送-学者数据测评分析报告
    {
      path: "/analysisReport",
      name: "analysisReport",
      component: () =>
        import(
          /* webpackChunkName:"analysisReport" */ "@/views/EmailPush/analysisReport"
        ),
      meta: { requireAuth: true, roles: allRolesPage }
    },
    // 微信传播-配置管理
    {
      path: "/webJournalTemplate",
      name: "webJournalTemplate",
      component: () =>
        import(
          /* webpackChunkName: 'weChatPush'*/ "@/views/WeChatPush/genericComponentTemplate/webJournalTemplate"
        ),
      meta: { requireAuth: true, roles: allRolesPage }
    },
    // 微信传播-模版编辑/新建
    {
      path: "/handleTemplate/:templateState/:templateId?",
      name: "handleTemplate",
      component: () =>
        import(
          /* webpackChunkName: 'weChatPush'*/ "@/views/WeChatPush/genericComponentTemplate/handleTemplate"
        ),
      meta: { requireAuth: true, roles: allRolesPage }
    },
    // 微信传播-基础配置
    {
      path: "/basicConfiguration",
      name: "basicConfiguration",
      component: () =>
        import(
          /* webpackChunkName: 'weChatPush'*/ "@/views/WeChatPush/basicConfiguration/basicConfiguration"
        ),
      meta: { requireAuth: true, roles: allRolesPage }
    },
    // 微信传播-新建任务
    {
      path: "/weChatTaskManagement",
      name: "weChatTaskManagement",
      component: () =>
        import(
          /* webpackChunkName:"Details" */ "@/views/WeChatPush/taskManagement"
        ),
      meta: { requireAuth: true, roles: allRolesPage }
    },
    // 微信传播-本刊模板
    {
      path: "/styleConfiguration",
      name: "styleConfiguration",
      component: () =>
        import(
          /* webpackChunkName:"Details" */ "@/views/WeChatPush/styleConfiguration"
        ),
      meta: { requireAuth: true, roles: allRolesPage }
    },
    // 微信传播-目录模版编辑/新建
    {
      path: "/handleCatalog/:templateState/:templateId?",
      name: "handleCatalog",
      component: () =>
        import(
          /* webpackChunkName: 'weChatPush'*/ "@/views/WeChatPush/genericComponentTemplate/handleCatalog"
        ),
      meta: { requireAuth: true, roles: allRolesPage }
    },
    // 设计师页面
    {
      path: "/designer",
      name: "designer",
      component: () => import("@/views/Designer/index"),
      meta: { requireAuth: true, roles: designerPage }
    },
    // 销售页面
    {
      path: "/sales",
      name: "sales",
      component: () => import("@/views/Sales/index"),
      meta: { requireAuth: true, roles: salePage }
    },
    // 销售页面
    {
      path: "/newApply",
      name: "newApply",
      component: () => import("@/views/Sales/newApply"),
      meta: { requireAuth: true, roles: salePage }
    },
    // 产品页面任务管理
    {
      path: "/PMTaskManagement",
      name: "PMTaskManagement",
      component: () => import("@/views/Product/PMTaskManagement"),
      meta: { requireAuth: true, roles: productPage }
    },
    // 推送结果一览列表页
    {
      path: "/previewPushResultsList",
      name: "previewPushResultsList",
      component: () =>
        import("@/views/Product/previewPushResults/previewPushResultsList"),
      meta: { requireAuth: true, roles: productPage }
    },
    // 推送结果一览详情页
    {
      path: "/previewPushResultsDetails",
      name: "previewPushResultsDetails",
      component: () =>
        import("@/views/Product/previewPushResults/previewPushResultsDetails"),
      meta: { requireAuth: true, roles: productPage }
    }
  ]
});
