<template>
  <div class="w center_all weChatPush">
    <div class="center_2 postion">
      <ul class="crumbs clearfix">
        <li>微信传播</li>
        <li>-></li>
        <li>任务管理</li>
      </ul>
      <!-- 新建任务按钮 -->
      <el-button class="new-btn fl" size="small" round icon="el-icon-plus" @click="newTask">新建</el-button>
      <!-- 新建任务弹框 -->
      <el-dialog class="newTask-dialog" :show-close="true" :visible.sync="newTaskDialogVisible" width="600px" title="新建任务">
        <el-form ref="newTaskForm" :model="newTaskForm" :rules="newTaskForm.type === '2' ? newTopicTaskRules : newWholePeriodTaskRules" status-icon label-width="120px" class="demo-ruleForm">
          <el-form-item label="内容类型：" prop="type" size="zdy" style="width:450px">
            <el-select v-model="newTaskForm.type" placeholder="请选择" style="width:325px">
              <el-option label="整期任务" value="1"></el-option>
              <el-option label="专题任务" value="2"></el-option>
              <el-option label="通知任务" value="3"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="微网刊标题：" size="zdy" prop="sysTopic" style="width:450px">
            <el-input id="sysTopic" v-model="newTaskForm.sysTopic" />
          </el-form-item>
          <el-form-item v-if="newTaskForm.type === '2'" label="年份：" prop="year" style="width:450px">
            <el-date-picker value-format="yyyy" v-model="newTaskForm.year" type="year" placeholder="请选择"> </el-date-picker>
          </el-form-item>
          <el-form-item v-if="newTaskForm.type === '2'" label="刊期：" size="zdy" prop="issue" style="width:450px">
            <el-input v-model="newTaskForm.issue" />
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="newTaskDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="newTaskBtn">确 定</el-button>
        </span>
      </el-dialog>
      <!-- 根据内容类型查找 -->
      <div class="task-type" v-if="haveTaskList">
        内容类型：
        <el-select v-model="taskType" placeholder="请选择" @change="taskTypeChange">
          <el-option v-for="item in taskTypeOptions" :key="item.value" :label="item.label" :value="item.value"> </el-option>
        </el-select>
      </div>
      <!-- 最新目次往期目录专题目录 -->
      <div class="settings" v-if="haveTaskList">
        <el-button icon="el-icon-menu" type="text" slot="reference" class="task-list-btn settings-btn" @click="showSettingsDialog">设置</el-button>
        <el-dialog width="500px" class="settings-dialog" :visible.sync="settingsDialogVisible" :show-close="true">
          <div class="url">
            <div>最新目次URL</div>
            <div class="right-info">
              <el-input type="textarea" v-model="newURL" disabled></el-input>
              <div class="info-btn">
                <el-button type="text" @click="copyURL(newURL)" :disabled="!newestJournalData">复制链接</el-button>
                <el-popover placement="left" width="172" trigger="click" @show="showURL('new')">
                  <div class="btn_fx">
                    <el-button size="yl" round @click="prviewNewURL">预览</el-button>
                    <div class="btn_fxd">分享到</div>
                    <div id="qrcodeNewURL"></div>
                  </div>
                  <el-button type="text" slot="reference" :disabled="!newestJournalData">预览</el-button>
                </el-popover>
              </div>
            </div>
          </div>
          <div class="url">
            <div>往期目录URL</div>
            <div class="right-info">
              <el-input type="textarea" v-model="oldURL" disabled></el-input>
              <div class="info-btn">
                <el-button type="text" @click="copyURL(oldURL)">复制链接</el-button>
                <el-popover placement="left" width="172" trigger="click" @show="showURL('old')">
                  <div class="btn_fx">
                    <el-button size="yl" round @click="prviewOldURL">预览</el-button>
                    <div class="btn_fxd">分享到</div>
                    <div id="qrcodeOldURL"></div>
                  </div>
                  <el-button type="text" slot="reference">预览</el-button>
                </el-popover>
              </div>
            </div>
          </div>
          <div class="url">
            <div>专题目录URL</div>
            <div class="right-info">
              <el-input type="textarea" v-model="topicURL" disabled></el-input>
              <div class="info-btn">
                <el-button type="text" @click="copyURL(topicURL)">复制链接</el-button>
                <el-popover placement="left" width="172" trigger="click" @show="showURL('topic')">
                  <div class="btn_fx">
                    <el-button size="yl" round @click="prviewTopicURL">预览</el-button>
                    <div class="btn_fxd">分享到</div>
                    <div id="qrcodeTopicURL"></div>
                  </div>
                  <el-button type="text" slot="reference">预览</el-button>
                </el-popover>
              </div>
            </div>
          </div>
        </el-dialog>
      </div>
      <!-- 任务列表 -->
      <taskList ref="taskList" v-model="taskListData" @changeTask="changeTask" :templateType="templateType" :taskType="taskType" @updateJournalPublish="updateJournalPublish"></taskList>
      <!-- 已发布禁用下面点击 -->
      <!-- :class="[isPublishDisabled]" -->
      <div :class="[isPublishDisabled]">
        <!-- 微网刊信息 -->
        <div class="updata weChat-news-push" v-if="showInfo">
          <el-form ref="ruleInfoForm" :model="ruleForm" :rules="rules" status-icon label-width="120px" class="demo-ruleForm">
            <el-form-item label="内容类型：" prop="type" size="zdy" style="width:728px">
              <el-select disabled v-model="ruleForm.type" placeholder="请选择" style="width:616px">
                <el-option label="整期任务" value="1"></el-option>
                <el-option label="专题任务" value="2"></el-option>
                <el-option label="通知任务" value="3"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="微网刊标题：" size="zdy" prop="sysTopic" style="width:728px">
              <el-input v-model="ruleForm.sysTopic" @change="saveWeChatInfo" />
              <div class="shili issue-style" :class="detailsIsShow ? '' : 'disabled-issue-style'" data-tooltip="请先选择文章！" @click="setStyleConfig">
                本期样式设置
              </div>
            </el-form-item>
            <el-form-item label="文章列表：" size="zdy" prop="list" style="width:728px;">
              <el-input v-model.number="ruleForm.list" disabled />
              <!-- <el-button :disabled="!chooseTaskId" class="upload-demo email-demo" size="small" type="primary" @click="emailPushDialogVisible = true">邮件文章</el-button> -->
              <el-button
                class="upload-demo article-demo"
                :class="[ruleForm.type === '1' ? 'right-article-demo' : '']"
                size="small"
                type="primary"
                :disabled="!haveArticle || !chooseTaskId"
                @click="chooseOfficialWebsiteArticle"
                >官网文章</el-button
              >
              <el-upload
                v-if="ruleForm.type !== '1'"
                ref="uploadExcel"
                name="file"
                :auto-upload="true"
                :before-upload="beforeUploadFile"
                :on-success="handleSuccess"
                :on-error="handleError"
                :data="articleForm"
                class="upload-demo official-demo"
                :action="articleWeChatUploadUrl"
                accept=".zip"
              >
                <el-button :disabled="!chooseTaskId" size="small" type="primary" v-loading.fullscreen.lock="fullscreenLoading">浏览</el-button>
              </el-upload>
              <div class="shili">
                <a href="#" @click="handleDownloadSample">样例文件</a>
              </div>
            </el-form-item>
          </el-form>
        </div>
        <!-- 本刊模板 -->
        <div class="style-config" v-if="showStyleConfig">
          <el-dialog width="1204px" class="style-config-dialog" :show-close="true" :visible.sync="showStyleConfig">
            <styleSettings ref="styleSettings" showCarouselSettings :chooseId="chooseTaskId" :carouselTableData="issueTableData" @savePublicationConfig="savePublicationConfig"></styleSettings>
          </el-dialog>
        </div>
        <!-- 新建微网刊每期文章按钮 -->
        <el-button class="new-btn fl" v-if="chooseTaskId" size="small" round icon="el-icon-plus" @click="newArticle">新增</el-button>
        <!-- 编辑微网刊每期文章按钮 -->
        <el-button class="new-btn fl" v-if="chooseTaskId && showIssueEdit" size="small" round icon="el-icon-edit" @click="editArticle">编辑</el-button>
        <el-button class="new-btn fl" size="small" round icon="el-icon-folder-add" @click="importHandle">导入</el-button>
        <el-button class="new-btn fl" size="small" round icon="el-icon-folder-remove" @click="exportHandle">导出</el-button>
        <!-- 新建编辑微网刊每期文章弹框 -->
        <el-dialog :show-close="true" :visible.sync="newArticleDialogVisible" width="600px" :title="newArticleTitle">
          <el-form ref="newArticleForm" :model="newArticleForm" :rules="newArticleRules" status-icon label-width="120px" class="new-article-form">
            <el-form-item label="推送文章标题：" size="zdy" prop="topic">
              <el-input id="topic" v-model="newArticleForm.topic" />
            </el-form-item>
            <el-form-item label="文章标题译文：" size="zdy" prop="titleTranslation">
              <el-input v-model="newArticleForm.titleTranslation" />
            </el-form-item>
            <el-form-item label="摘要：" size="zdy" prop="articleAbstract">
              <el-input type="textarea" autosize v-model="newArticleForm.articleAbstract" />
            </el-form-item>
            <el-form-item label="栏目：" size="zdy" prop="articleColumn">
              <el-input v-model="newArticleForm.articleColumn" />
            </el-form-item>
            <el-form-item label="栏目译文：" size="zdy" prop="articleColumnTranslation">
              <el-input v-model="newArticleForm.articleColumnTranslation" />
            </el-form-item>
            <el-form-item label="机构：" size="zdy" prop="org">
              <el-input type="textarea" autosize v-model="newArticleForm.org" />
            </el-form-item>
            <el-form-item label="作者：" size="zdy" prop="authors">
              <el-input v-model="newArticleForm.authors" />
            </el-form-item>
            <el-form-item label="作者译文：" size="zdy" prop="authorsTranslation">
              <el-input v-model="newArticleForm.authorsTranslation" />
            </el-form-item>
            <el-form-item label="关键词：" size="zdy" prop="keywords">
              <el-input v-model="newArticleForm.keywords" />
            </el-form-item>
            <el-form-item label="关键词译文：" size="zdy" prop="keywordsTranslation">
              <el-input v-model="newArticleForm.keywordsTranslation" />
            </el-form-item>
            <el-form-item label="全文URL：" size="zdy" prop="link">
              <el-input type="textarea" autosize v-model="newArticleForm.link" />
            </el-form-item>
            <el-form-item label="推送详情URL：" size="zdy" prop="wechatUrl">
              <el-input type="textarea" autosize v-model="newArticleForm.wechatUrl" />
            </el-form-item>
            <el-form-item label="预览图：" size="zdy4" prop="recodeId">
              <div class="new-article-img-box">
                <div class="new-article-img">
                  <img class="fl" :src="newArticleCoverSrc ? newArticleCoverSrc : morImg" alt />
                </div>
                <el-upload
                  :action="UploadPictureStream"
                  name="file"
                  :show-file-list="false"
                  accept=".png,.jpg,.jpeg"
                  class="new-article-upload"
                  :auto-upload="true"
                  :on-success="handleNewArticleCoverSuccess"
                >
                  <el-button size="small" type="primary">上传图片</el-button>
                </el-upload>
              </div>
            </el-form-item>
          </el-form>
          <span slot="footer" class="dialog-footer">
            <el-button @click="newArticleDialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="newArticleBtn">确 定</el-button>
          </span>
        </el-dialog>
        <!-- 微网刊每期文章列表 -->
        <div class="upData_table conter_3 issue-table" v-if="showInfo">
          <SimpleTable
            ref="issueTable"
            maxHeight="840px"
            noPagination
            hasSelect
            showUpBottom
            showDelete
            showOperate
            showUpload
            showBtn
            isDisabledClick
            showOperateText="预览图"
            btn1Text="查看"
            showBtnText="官网文章"
            :showBtn2="false"
            :uploadUrl="UploadPictureStream"
            :tableData="issueTableData"
            :columnData="issueArticleColumn"
            :disabledEvents="basicInfo.isPublish === 1"
            @onClickBtn1="handlePreviewImg"
            @onHandleUploadSuccess="handleUploadSuccess"
            @onChangeInput="handleIssueChangeInput"
            @onChangeInputBlur="handleIssueChangeInputBlur"
            @onClickTitle="handleResultOpenUrl"
            @onChangeToTop="handleToTop"
            @onChangeToBottom="handleToBottom"
            @onChangeDeleteItems="handleDeleteItems"
            @onClickBtn="handleHtmlUrlJump"
            @onChangeSelection="handleChangeSelection"
          />
          <!-- 任务文章列表封面预览 -->
          <el-dialog :visible.sync="previewImgDialogVisible" width="30%">
            <div class="preview-img-dialog">
              <img :src="issueCoverSrc" />
            </div>
          </el-dialog>
        </div>
      </div>
      <!-- 微网刊底部按钮s -->
      <div class="weChat-btns btns" v-if="showInfo">
        <!-- 微网刊详情页 -->
        <el-popover class="btn-margin-left" placement="top" width="172" trigger="click" ref="popover">
          <div class="btn_fx">
            <el-button size="yl" round @click="preview">预览</el-button>
            <div class="btn_fxd">分享到</div>
            <div id="qrcodePre"></div>
          </div>
          <el-button slot="reference" :disabled="!detailsIsShow || !templateType" @click="qrcode">微网刊预览</el-button>
        </el-popover>
        <!-- 发布任务 -->
        <el-button class="save-weChat-info btn-margin-left" :disabled="!templateType" @click="updateJournalPublish">{{ publishText }}</el-button>
      </div>
      <!-- 邮件推送弹框 -->
      <el-dialog :visible.sync="emailPushDialogVisible" width="300px" :show-close="true">
        <p>确定选用邮件文章数据吗</p>
        <span slot="footer" class="dialog-footer">
          <el-button @click="emailPushDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="chooseEmailArticles">确 定</el-button>
        </span>
      </el-dialog>
      <!-- 微文章弹框 -->
      <officialWebsiteArticleDialog
        ref="officialWebsiteArticle"
        type="weChat"
        :publicationTopic="ruleForm.sysTopic"
        :taskId="taskId"
        :taskInfo="basicInfo"
        @SaveOfficialArticle="saveOfficialArticle"
      ></officialWebsiteArticleDialog>
    </div>
    <importDialog ref="importDialogRef" :networkId="taskId" @getList="getWeChatArticles" />
  </div>
</template>

<script>
import SimpleTable from '@/components/SimpleTable'
import taskList from './components/taskList'
import officialWebsiteArticleDialog from '../EmailPush/components/officialWebsiteArticleDialog'
import styleSettings from './components/styleSettings'
import importDialog from './components/importTemplat.vue'
import QRCode from 'qrcodejs2'
import { mapGetters } from 'vuex'
import {
  articleWeChatUploadUrl,
  SampleDownload,
  ArticleReport,
  GetIssuesByMagId,
  SetEmailArticles,
  GetNetworkArticles,
  EditNetworkJournal,
  UpdateIssueArticleList,
  GetHistoryList
} from '@/api/selfPush'
import {
  UpdateJournalPublish,
  GetNetworkJournalInfo,
  SaveNetworkJournal,
  IssueUpOrBottom,
  IssueDelete,
  UploadIssueCover,
  NewIssueArticle,
  GetConfig,
  GetNewestJournal,
  GetIndex
} from '@/api/weChatPush'
import { getTemplate, GetPictureStream, UploadPictureStream } from '@/api/configManage'
import { WeChatAllJumpUrl } from '@/assets/js/util'

export default {
  name: 'taskManagement',
  computed: {
    ...mapGetters({
      platMagId: 'platMagId',
      platTenantId: 'platTenantId',
      platMagName: 'platMagName',
      magId: 'magId'
    }),
    detailsIsShow() {
      return this.chooseTaskId && this.issueTableData.length > 0 ? true : false
    },
    publishText() {
      return this.basicInfo.isPublish === 1 ? '撤销发布' : '发布'
    },
    isPublishDisabled() {
      return this.basicInfo.isPublish === 1 ? 'is-publish-disabled' : ''
    },
    newURL() {
      return this.newestJournalData ? WeChatAllJumpUrl(this.magId) : '暂无最新目次'
    },
    oldURL() {
      let origin = window.location.origin
      return `${origin}/otherPages/Microblog/html/catalog.html?magId=${this.magId}`
    },
    topicURL() {
      let origin = window.location.origin
      return `${origin}/otherPages/Microblog/html/topicCatalog.html?magId=${this.magId}`
    },
    taskListLength() {
      return this.taskListData && this.taskListData.length > 0
    },
    showIssueDelete() {
      return this.issueChooseId
    },
    showIssueEdit() {
      return this.issueChooseId && this.issueChooseId.indexOf(',') === -1
    },
    newArticleTitle() {
      return this.issueNewEditStatus === 'new' ? '新增文章' : this.issueNewEditStatus === 'edit' ? '编辑文章' : ''
    }
  },
  components: {
    SimpleTable,
    officialWebsiteArticleDialog,
    styleSettings,
    taskList,
    importDialog
  },
  watch: {
    magId() {
      this.initData()
      this.getIssuesByMagId()
      this.getConfig()
      this.getAllTaskList()
      this.$nextTick(() => {
        if (this.$refs['popover']) {
          this.$refs['popover'].doClose()
        }
      })
    },
    'taskListData.length'(n) {
      if (n === 0) {
        this.showInfo = false
      }
    }
  },
  data() {
    return {
      articleWeChatUploadUrl,
      GetPictureStream: GetPictureStream,
      UploadPictureStream: UploadPictureStream,
      morImg: require('@/assets/img/img_default.png'),
      articleForm: {
        topic: '',
        tenantId: '', //租户ID
        publicationId: '', //刊物ID
        magName: '',
        magId: '',
        sysTopic: '', //微网刊标题
        type: '',
        networkId: '' // 任务id
      },
      ruleForm: {
        list: '', // 文章列表
        sysTopic: '', // 微网刊主题
        type: '1', // 内容类型 1是整期任务2是专题任务
        name: '', //网刊样式
        id: '' // 期刊id
      },
      newFormData: {
        name: '', //本刊模板名称
        language: 'zh', //本刊语言
        magId: '', // 期刊id
        templateId: '', //网刊模板id
        weChatShareCoverId: '', //微信分享链接图片id
        weChatShareTitle: '', //微信分享标题
        weChatShareContent: '', //微信分享内容
        subjectTitleCoverId: '', //专题顶部介绍附件id
        subjectCoverId: '', //专题顶部介绍封面id
        subjectInfo: '', //专题顶部介绍
        subjectTitle: '', //专题顶部标题
        subjectHref: '', //专题顶部跳转
        subjectEnTitle: '', // 英文标题
        subjectTiltleColor: '', //专题顶部标题配色
        magCoverId: '', //期刊底部介绍封面附件id
        magHref: '', //期刊底部介绍封面url
        magInfo: '', //期刊底部介绍
        magTitle: '', //期刊底部标题
        magTitleColor: '', //期刊底部介绍标题配色
        magHrefInfo: '', // 期刊底部封面故事介绍
        deRotationCoverId: '', // 轮播图左侧图片
        docIds: '', //轮播图自选文章Ids
        htmlOrder: '' // 文章地址类型优先级 htmlUrl:全文 abstractUrl:摘要 wechatUrl:微信 url :知网
      },
      fullscreenLoading: false, // 等待loading 是否开启
      rules: {
        type: [{ message: '请选择内容类型', trigger: 'change', required: true }],
        sysTopic: [{ message: '请填写微网刊标题', trigger: 'blur', required: true }]
      },
      issueTableData: [], // 每期列表数据
      id: null,
      taskId: null, //获取列表
      issueArticleColumn: [
        // 每期列表数据
        {
          title: '推送文章标题',
          prop: 'topic',
          notSortable: true,
          edit: true,
          clickParam: 'link'
          // special: true
        },
        {
          title: '文章标题译文',
          prop: 'titleTranslation',
          notSortable: true,
          edit: true,
          clickParam: 'link'
          // special: true
        },
        {
          title: '栏目',
          prop: 'articleColumn',
          notSortable: true,
          edit: true
        },
        {
          title: '栏目译文',
          prop: 'articleColumnTranslation',
          notSortable: true,
          edit: true
        },
        {
          title: '机构',
          prop: 'org',
          notSortable: true,
          edit: true
        },
        {
          title: '作者',
          prop: 'authors',
          notSortable: true,
          edit: true
        },
        {
          title: '作者译文',
          prop: 'authorsTranslation',
          notSortable: true,
          edit: true
        },
        // {
        //   title: "刊期",
        //   prop: "pubYearStage",
        //   notSortable: true,
        //   edit: true
        // },
        {
          title: '关键词',
          prop: 'keywords',
          notSortable: true,
          edit: true
        },
        {
          title: '关键词译文',
          prop: 'keywordsTranslation',
          notSortable: true,
          edit: true
        },
        {
          title: 'URL',
          prop: 'link',
          notSortable: true,
          edit: true
        }
      ],
      haveArticle: false, //是否有官方文章
      historyTableData: [], // 历史网刊列表数据
      emailPushDialogVisible: false, // 邮件推送弹框
      basicInfo: '',
      templateType: null, // 期刊模板类型
      showStyleConfig: false,
      showInfo: false,
      chooseTaskId: '',
      styleConfigList: [],
      issueStyleConfig: {},
      newTaskDialogVisible: false,
      newTaskForm: {
        type: '1',
        sysTopic: '',
        year: '',
        issue: ''
      },
      newWholePeriodTaskRules: {
        type: [{ message: '请选择内容类型', trigger: 'blur', required: true }],
        sysTopic: [{ message: '请填写微网刊标题', trigger: 'blur', required: true }]
      },
      newTopicTaskRules: {
        type: [{ message: '请选择内容类型', trigger: 'blur', required: true }],
        sysTopic: [{ message: '请填写微网刊标题', trigger: 'blur', required: true }],
        year: [{ message: '请选择微网刊年份', trigger: 'change', required: true }],
        issue: [{ message: '请填写微网刊刊期', trigger: 'blur', required: true }]
      },
      settingsDialogVisible: false,
      newArticleDialogVisible: false,
      newArticleForm: {
        topic: '',
        articleAbstract: '',
        articleColumn: '',
        org: '',
        authors: '',
        keywords: '',
        link: '',
        wechatUrl: '',
        recodeId: '',
        titleTranslation: '',
        articleColumnTranslation: '',
        authorsTranslation: '',
        keywordsTranslation: ''
      },
      newArticleRules: {
        topic: [{ message: '请填写推送文章标题', trigger: 'blur', required: true }]
      },
      newArticleCoverSrc: '',
      previewImgDialogVisible: false,
      issueCoverSrc: '',
      taskType: '',
      taskTypeOptions: [
        { label: '全部', value: '' },
        { label: '整期任务', value: 1 },
        { label: '专题任务', value: 2 },
        { label: '通知任务', value: 3 }
      ],
      taskListData: null,
      issueChooseId: '',
      issueChooseItem: '',
      issueNewEditStatus: '',
      haveTaskList: '',
      newestJournalData: '' //最新目次期信息
    }
  },
  mounted() {
    if (this.magId) {
      this.initData()
      this.getIssuesByMagId()
      this.getConfig()
      this.getAllTaskList()
    }
  },
  methods: {
    importHandle() {
      this.$refs.importDialogRef.open()
    },
    exportHandle() {
      const url = `${ArticleReport}?networkId=${this.taskId}`
      console.log('url:', url)
      window.open(url, '_self')
    },
    // 初始化数据
    initData() {
      this.issueTableData = []
      this.ruleForm = {
        list: '',
        sysTopic: '',
        type: '1'
      }
      this.showInfo = false
      this.taskType = ''
    },
    // 获取基础配置判断期刊类型 1是自科2是社科
    getConfig() {
      let params = {
        magId: this.magId
      }
      GetConfig(params).then(resp => {
        let { data, status } = resp.data
        if (status === 0) {
          this.newFormData.htmlOrder = data.htmlOrder || 'htmlUrl,abstractUrl,wechatUrl,url'
        }
      })
    },
    // 新建任务
    newTask() {
      this.newTaskDialogVisible = true
      this.$nextTick(() => {
        this.$refs['newTaskForm'].resetFields()
      })
    },
    // 新建任务确定
    newTaskBtn() {
      this.newTaskForm = { magId: this.magId, ...this.newTaskForm }
      this.$refs['newTaskForm'].validate(valid => {
        if (!valid) {
          return false
        } else {
          SaveNetworkJournal(this.newTaskForm).then(res => {
            if (res && res.data && res.data.status === 0) {
              this.$message({
                message: '新建任务成功！',
                duration: 1500,
                type: 'success'
              })
              let chooseTaskId = res.data.data.id
              this.$refs['newTaskForm'].resetFields()
              this.newTaskDialogVisible = false
              let params = {
                magId: this.magId,
                networkId: chooseTaskId
              }
              GetIndex(params).then(res => {
                if (res && res.data && res.data.status === 0) {
                  let { data } = res.data
                  this.$nextTick(() => {
                    let obj = { ...this.$refs.taskList.taskListPageObj }
                    obj.page = parseInt(data / this.$refs.taskList.taskListPageObj.size) + 1
                    this.$refs.taskList.getTaskList(obj, 'edit', chooseTaskId)
                  })
                } else {
                  const { message = '' } = res.data
                  this.$message.error(message)
                }
              })
            } else {
              const { message = '' } = res.data
              this.$message.error(message)
            }
          })
        }
      })
    },
    // 点击设置
    showSettingsDialog() {
      this.getNewestJournal()
    },
    // 获取最新目次期信息
    getNewestJournal() {
      let params = {
        magId: this.magId
      }
      GetNewestJournal(params).then(res => {
        if (res && res.data && res.data.status === 0) {
          const { data } = res.data
          this.settingsDialogVisible = true
          this.newestJournalData = data
        } else {
          const { message = '' } = res.data
          this.$message.error(message)
        }
      })
    },
    // 内容类型切换
    taskTypeChange() {
      this.$nextTick(() => {
        this.$refs.taskList.getTaskList()
        if (this.$refs['popover']) {
          this.$refs['popover'].doClose()
        }
      })
    },
    getAllTaskList(pageParams = {}) {
      let { page = 1, size = 10, sort = 'year,issue,desc' } = pageParams
      let params = {
        magId: this.magId,
        page: page - 1,
        size,
        sort,
        type: ''
      }
      GetHistoryList(params).then(res => {
        if (res && res.data && res.data.status === 0) {
          const { data } = res.data
          this.haveTaskList = data.content.length > 0
        } else {
          this.$message.error(res.message)
        }
      })
    },
    // 切换任务列表数据
    changeTask(chooseTaskId) {
      this.chooseTaskId = chooseTaskId
      this.issueChooseId = ''
      this.issueChooseItem = ''
      if (chooseTaskId) {
        this.showInfo = true
        this.getNetworkJournalInfo(chooseTaskId)
        this.issueStyleConfig = {}
        this.ruleForm.id = chooseTaskId
        this.ruleForm.list = ''
        this.$nextTick(() => {
          this.$refs.issueTable.initData()
        })
      }
    },
    // 获取每期任务基本信息
    getNetworkJournalInfo(id) {
      let params = {
        id: id
      }
      GetNetworkJournalInfo(params).then(res => {
        if (res && res.data && res.data.status === 0) {
          const { data } = res.data
          this.taskId = data.id
          this.basicInfo = data
          this.templateType = data.templateType
          this.ruleForm.type = String(data.type)
          this.ruleForm.sysTopic = data.sysTopic
          // 需要任务id的接口在此请求
          if (this.taskId) {
            this.getWeChatArticles()
          }
        } else {
          const { message = '' } = res.data
          this.$message.error(message)
        }
      })
    },
    // 点击每期样式设置
    setStyleConfig() {
      if (this.detailsIsShow) {
        this.showStyleConfig = true
        for (var it in this.basicInfo) {
          if (this.newFormData.hasOwnProperty(it)) {
            this.ruleForm[it] = this.basicInfo[it] || this.newFormData[it]
          }
        }
        this.ruleForm.name = ''
        this.$nextTick(() => {
          this.$refs.styleSettings.resetForm()
          this.$refs.styleSettings.getConfig('editIssue', this.ruleForm)
        })
      }
    },
    // 获取微网刊样式模板
    getStyleConfigList() {
      let params = {
        magId: this.magId,
        page: 0,
        size: 10
      }
      GetListConfig(params).then(res => {
        if (res && res.data && res.data.status === 0) {
          const { data } = res.data
          this.styleConfigList = data.content
        } else {
          const { message = '' } = res.data
          this.$message.error(message)
        }
      })
    },
    // 获取每期样式设置数据
    savePublicationConfig(formData, type) {
      this.showStyleConfig = false
      this.ruleForm = formData
      this.saveWeChatInfo()
      this.getNetworkJournalInfo(this.chooseTaskId)
    },
    // <-- 文章列表开始 -->
    // 选择邮件文章数据为微网刊
    chooseEmailArticles() {
      let params = {
        magId: this.magId,
        type: this.ruleForm.type,
        networkId: this.taskId
      }
      SetEmailArticles(params).then(res => {
        if (res && res.data && res.data.status === 0) {
          this.emailPushDialogVisible = false
          this.getWeChatArticles()
        } else {
          const { message = '' } = res.data
          this.$message.error(message)
        }
      })
    },
    //根据任务id获取列表判断官网文章是否能选择
    getIssuesByMagId(val) {
      let params = {
        id: this.platMagId,
        magId: this.magId,
        size: 999
      }
      GetIssuesByMagId(params).then(res => {
        if (res && res.data && res.data.status === 0) {
          const { data } = res.data
          this.haveArticle = data.content.length > 0 ? true : false
          this.fullscreenLoading = false
        }
      })
    },
    //点击官网文章
    chooseOfficialWebsiteArticle() {
      this.$nextTick(() => {
        this.$refs.officialWebsiteArticle.showOfficialWebsiteArticle = true
      })
    },
    // 上传文件之前的钩子, 参数为上传的文件,若返回 false 或者返回 Promise 且被 reject，则停止上传
    beforeUploadFile(file) {
      let fileType = ''
      let arr = ['zip']
      let filePath = file.name
      let fileNameLength = file.name.lastIndexOf('.')
      if (fileNameLength != -1) {
        fileType = filePath.substring(fileNameLength + 1, filePath.length).toLowerCase()
      } else {
        fileType = ''
      }
      if (!arr.includes(fileType)) {
        this.$message({
          message: '请上传' + arr + '类型文件！',
          duration: 1500,
          type: 'error'
        })
        return false
      }
      if (this.ruleForm.sysTopic) {
        this.ruleForm.list = file.name
        this.fullscreenLoading = true // 等待loading 开启
        this.articleForm.publicationId = this.platMagId
        this.articleForm.tenantId = this.platTenantId
        this.articleForm.magName = this.platMagName
        this.articleForm.magId = this.magId
        this.articleForm.sysTopic = this.ruleForm.sysTopic
        this.articleForm.type = this.ruleForm.type
        this.articleForm.networkId = this.taskId
      } else {
        this.$notify.warning({
          title: '警告',
          message: `请输入标题`
        })
        return false
      }
    },
    // 文件上传成功时的钩子
    handleSuccess(res, file, fileList) {
      this.$refs.uploadExcel.clearFiles() // 清除上次上传记录
      if (res && res.status === 0) {
        setTimeout(() => {
          this.getWeChatArticles()
        }, 2000)
      } else {
        const { message = '' } = res
        fileList.pop()
        this.ruleForm.list = ''
        this.$message.error(message)
        this.fullscreenLoading = false // 等待loading 关闭
      }
    },
    // 文件上传失败时的钩子
    handleError(err, file, fileList) {
      this.$message.error(err.msg)
      this.fullscreenLoading = false // 等待loading 关闭
    },
    // 下载样例文件
    handleDownloadSample() {
      window.open(SampleDownload, '_self')
    },
    // <-- 文章列表结束 -->
    // <!-- 微网刊每期文章列表开始 -->
    //新增微网刊每期文章
    newArticle() {
      this.newArticleDialogVisible = true
      this.issueNewEditStatus = 'new'
      this.newArticleCoverSrc = ''
      this.newArticleForm = {
        topic: '',
        articleAbstract: '',
        articleColumn: '',
        org: '',
        authors: '',
        keywords: '',
        link: '',
        wechatUrl: '',
        recodeId: '',
        titleTranslation: '',
        articleColumnTranslation: '',
        authorsTranslation: '',
        keywordsTranslation: ''
      }
      this.$nextTick(() => {
        this.$refs['newArticleForm'].resetFields()
      })
    },
    //新增微网刊每期文章封面图上传成功
    handleNewArticleCoverSuccess(res, file) {
      const { data } = res
      const { ID } = data
      this.newArticleCoverSrc = URL.createObjectURL(file.raw)
      this.newArticleForm.recodeId = ID
    },
    //新增微网刊每期文章确定
    newArticleBtn() {
      if (this.issueNewEditStatus === 'new') {
        let params = {
          magId: this.magId,
          networkId: this.chooseTaskId,
          ...this.newArticleForm
        }
        this.$refs['newArticleForm'].validate(valid => {
          if (!valid) {
            document.getElementById('topic').focus()
          } else {
            NewIssueArticle(params).then(res => {
              if (res && res.data && res.data.status === 0) {
                this.$message({
                  message: '新建文章成功！',
                  duration: 1500,
                  type: 'success'
                })
                this.newArticleDialogVisible = false
                this.getWeChatArticles()
              } else {
                const { message = '' } = res.data
                this.$message.error(message)
              }
            })
          }
        })
      }
      if (this.issueNewEditStatus === 'edit') {
        this.$refs['newArticleForm'].validate(valid => {
          if (!valid) {
            document.getElementById('topic').focus()
          } else {
            let params = {
              networkArticleId: this.issueChooseId,
              ...this.newArticleForm
            }
            UpdateIssueArticleList(params).then(resp => {
              let { data, status } = resp.data
              if (status !== 0) {
                this.$message({
                  type: 'error',
                  message: data.message
                })
              } else {
                this.$message({
                  type: 'success',
                  message: '修改成功!'
                })
                this.newArticleDialogVisible = false
                this.getWeChatArticles('edit')
              }
            })
          }
        })
      }
    },
    // 编辑微网刊每期文章
    editArticle() {
      this.issueTableData.map(item => {
        if (item.id == this.issueChooseId) {
          this.issueChooseItem = item
        }
      })
      this.newArticleDialogVisible = true
      this.issueNewEditStatus = 'edit'
      for (var item in this.newArticleForm) {
        if (this.issueChooseItem.hasOwnProperty(item)) {
          this.newArticleForm[item] = this.issueChooseItem[item]
        }
      }
    },
    // 获取微网刊下的微文章列表
    getWeChatArticles(type, item) {
      let params = {
        id: this.taskId
      }
      GetNetworkArticles(params).then(res => {
        if (res && res.data && res.data.status === 0) {
          this.issueTableData = res.data.data
          if (this.issueTableData.length > 0) {
            this.issueTableData.map((item, index) => {
              item.activeBtn1 = typeof item.recodeId === 'number'
              item.activeBtn = item.htmlUrl ? true : false
            })
          }
          this.fullscreenLoading = false // 等待loading 关闭
          if (type === 'move') {
            this.$nextTick(() => {
              this.$refs.issueTable.initData()
              this.$refs.issueTable.cumputedChooseCurrentIndex(item)
              // this.$refs.issueTable.$refs.simpleTable.clearSelection()
              // this.issueChooseId = ''
            })
          } else if (type === 'delete') {
            this.$nextTick(() => {
              this.$refs.issueTable.initData()
            })
          }
        } else {
          const { message = '' } = res.data
          this.$message.error(message)
        }
      })
    },
    // 跳转页面
    handleResultOpenUrl(chooseItem, columnItem) {
      const { clickParam = '' } = columnItem
      if (clickParam && clickParam !== '' && chooseItem[clickParam] !== '') {
        window.open(chooseItem[clickParam])
      }
    },
    // 点击微网刊每篇文章
    handleChangeSelection(val) {
      this.issueChooseId = ''
      val.map((item, index) => {
        this.issueChooseId = this.issueChooseId + item.id + (index === val.length - 1 ? '' : ',')
      })
    },
    // 处理每期微网刊文章列表切换为input框
    handleIssueChangeInput(val, prop, event, index) {},
    // 处理每期微网刊文章列表为input框时失焦事件
    handleIssueChangeInputBlur(val, prop, event, index, content) {
      console.log(val, prop, event, index, content)
      let params = {
        networkArticleId: this.issueTableData[index].id,
        topic: prop === 'topic' ? content : this.issueTableData[index].topic,
        articleColumn: prop === 'articleColumn' ? content : this.issueTableData[index].articleColumn,
        link: prop === 'link' ? content : this.issueTableData[index].link,
        org: prop === 'org' ? content : this.issueTableData[index].org,
        authors: prop === 'authors' ? content : this.issueTableData[index].authors,
        keywords: prop === 'keywords' ? content : this.issueTableData[index].keywords,
        pubYearStage: prop === 'pubYearStage' ? content : this.issueTableData[index].pubYearStage,
        titleTranslation: prop === 'titleTranslation' ? content : this.issueTableData[index].titleTranslation,
        articleColumnTranslation: prop === 'articleColumnTranslation' ? content : this.issueTableData[index].articleColumnTranslation,
        authorsTranslation: prop === 'authorsTranslation' ? content : this.issueTableData[index].authorsTranslation,
        keywordsTranslation: prop === 'keywordsTranslation' ? content : this.issueTableData[index].keywordsTranslation
      }
      UpdateIssueArticleList(params).then(resp => {
        let { data, status } = resp.data
        if (status !== 0) {
          this.$message({
            type: 'error',
            message: data.message
          })
        } else {
          this.$message({
            type: 'success',
            message: '修改成功!'
          })
          this.getWeChatArticles()
        }
      })
    },
    // 微网刊每期文章列表数据上移
    handleToTop(index, chooseId, item) {
      this.articleUpOrBottom(item.id, 'up', item)
    },
    // 微网刊每期文章列表数据下移
    handleToBottom(index, chooseId, item) {
      this.articleUpOrBottom(item.id, 'down', item)
    },
    // 微网刊每期文章列表数据上移下移接口
    articleUpOrBottom(id, type, item) {
      let params = {
        id: id,
        type: type === 'up' ? -1 : 1
      }
      IssueUpOrBottom(params).then(res => {
        if (res && res.data && res.data.status === 0) {
          this.$message({
            type: 'success',
            message: '移动成功!'
          })
          this.getWeChatArticles('move', item)
        } else {
          const { message = '' } = res.data
          this.$message.error(message)
        }
      })
    },
    // 微网刊每期文章列表数据删除
    handleDeleteItems(chooseId) {
      let params = {
        id: this.issueChooseId
      }
      IssueDelete(params).then(res => {
        if (res && res.data && res.data.status === 0) {
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
          this.issueChooseId = ''
          this.getWeChatArticles('delete')
          this.$nextTick(() => {
            this.$refs.issueTable.$refs.simpleTable.clearSelection()
          })
        } else {
          const { message = '' } = res.data
          this.$message.error(message)
        }
      })
    },
    //微网刊每期文章列表数据图片预览
    handlePreviewImg(item) {
      this.previewImgDialogVisible = true
      this.issueCoverSrc = ''
      this.issueCoverSrc = `${this.GetPictureStream}?pictureId=${item.recodeId}`
    },
    //微网刊每期文章列表数据图片上传成功后
    handleUploadSuccess(id, url, item) {
      const params = {
        coverId: id,
        networkArticleId: item.id
      }
      UploadIssueCover(params).then(res => {
        if (res && res.data && res.data.status === 0) {
          this.getWeChatArticles()
          this.$message({
            type: 'success',
            message: '上传成功'
          })
        }
      })
    },
    // 选中官网文章确定
    saveOfficialArticle() {
      this.getWeChatArticles()
      this.getNetworkJournalInfo(this.chooseTaskId)
      let params = {
        magId: this.magId,
        networkId: this.chooseTaskId
      }
      GetIndex(params).then(res => {
        if (res && res.data && res.data.status === 0) {
          let { data } = res.data
          this.$nextTick(() => {
            let obj = { ...this.$refs.taskList.taskListPageObj }
            obj.page = parseInt(data / this.$refs.taskList.taskListPageObj.size) + 1
            this.$refs.taskList.getTaskList(obj, 'edit', this.chooseTaskId)
          })
        } else {
          const { message = '' } = res.data
          this.$message.error(message)
        }
      })
    },
    // 处理每期文章官网文章跳转
    handleHtmlUrlJump(item) {
      window.open(item.htmlUrl, '_blank')
    },
    // <!-- 微网刊每期文章列表结束 -->
    // 微网刊底部按钮开始
    // 复制链接
    copyURL(data) {
      var oInput = document.createElement('input')
      oInput.value = data
      document.body.appendChild(oInput)
      oInput.select() // 选择对象
      document.execCommand('Copy') // 执行浏览器复制命令
      oInput.className = 'oInput'
      oInput.style.display = 'none'
      // this.$message.closeAll()
      this.$message({
        message: '复制成功!',
        type: 'success'
      })
    },
    //点击设置预览显示预览二维码弹框
    showURL(type) {
      let idNode = ''
      if (type === 'new') {
        idNode = 'qrcodeNewURL'
      } else if (type === 'old') {
        idNode = 'qrcodeOldURL'
      } else if (type === 'topic') {
        idNode = 'qrcodeTopicURL'
      }
      document.getElementById(idNode).innerHTML = ''
      let qrcode = new QRCode(idNode, {
        width: 120,
        height: 120,
        colorDark: '#000',
        colorLight: '#fff'
      })
      if (type === 'new') {
        qrcode.makeCode(this.newURL)
      } else if (type === 'old') {
        qrcode.makeCode(this.oldURL)
      } else if (type === 'topic') {
        qrcode.makeCode(this.topicURL)
      }
    },
    //跳转最新目次
    prviewNewURL() {
      window.open(this.newURL)
    },
    //跳转往期目次
    prviewOldURL() {
      window.open(this.oldURL)
    },
    //跳转专题目次
    prviewTopicURL() {
      window.open(this.topicURL)
    },
    // 生成二维码微网刊详情页
    qrcode() {
      document.getElementById('qrcodePre').innerHTML = ''
      let qrcode = new QRCode('qrcodePre', {
        width: 120,
        height: 120,
        colorDark: '#000',
        colorLight: '#fff'
      })
      qrcode.makeCode(WeChatAllJumpUrl(this.magId, this.taskId))
    },
    // 预览微网刊详情页
    preview() {
      window.open(WeChatAllJumpUrl(this.magId, this.taskId))
    },
    // 保存微网刊基本信息
    saveWeChatInfo() {
      this.$refs['ruleInfoForm'].validate(valid => {
        if (!valid) {
          return false
        } else {
          EditNetworkJournal(this.ruleForm).then(res => {
            if (res && res.data && res.data.status === 0) {
              this.$message({
                message: '保存成功！',
                duration: 1500,
                type: 'success'
              })
              this.$nextTick(() => {
                this.$refs.taskList.getTaskList(this.$refs.taskList.taskListPageObj, 'edit', this.ruleForm.id)
              })
            } else {
              const { message = '' } = res.data
              this.$message.error(message)
            }
          })
        }
      })
    },
    // 发布撤销发布按钮
    updateJournalPublish() {
      let params = {
        networkId: this.taskId,
        type: this.basicInfo.isPublish === 1 ? 0 : 1
      }
      UpdateJournalPublish(params).then(res => {
        if (res && res.data && res.data.status === 0) {
          this.$message({
            type: 'success',
            message: res.data.message
          })
          this.$nextTick(() => {
            this.$refs.taskList.getTaskList(this.$refs.taskList.taskListPageObj, 'publish', this.chooseTaskId)
          })
          this.getNetworkJournalInfo(this.chooseTaskId)
        } else {
          const { message = '' } = res.data
          this.$message.error(message)
        }
      })
    }
    // 微网刊底部按钮结束
  }
}
</script>
<style lang="scss">
.weChatPush {
  .weChat-news-push {
    .el-form-item__error {
      top: 30px;
      left: 0;
    }
    .updata,
    .el-input.is-disabled .el-input__inner {
      background: #fff;
    }
  }
  .task-list-btn {
    .el-icon-menu {
      font-size: 20px;
    }
    span {
      margin-top: -10px;
      margin-left: -10px;
    }
  }
  .upData_table {
    .up-bottom-btns,
    .delete {
      float: left;
    }
  }
  .settings {
    .settings-dialog {
      .url {
        .el-textarea {
          width: 350px !important;
        }
        .right-info {
          .el-button--text {
            top: 0;
            text-indent: 0;
          }
          .el-textarea.is-disabled .el-textarea__inner {
            cursor: default;
          }
        }
      }
    }
  }
  .style-config-dialog {
    z-index: 2 !important;
  }
  .newTask-dialog {
    .el-date-editor--year {
      width: 100%;
    }
  }
  .issue-table {
    .el-button--text {
      text-indent: 0;
      top: 0;
      margin-left: 0;
      padding: 0;
    }
    .el-table .cell {
      text-align: center;
    }
  }
}
</style>
<style scoped lang="scss">
.selfPush {
  .postion {
    position: relative;
  }
}
.updata {
  background: #f5f7fd;
  padding: 40px 0;
  padding-left: 150px;
  position: relative;
}
.el-dialog__wrapper {
  background: rgba(0, 0, 0, 0.3) !important;
  padding: 0;
}
.form span {
  line-height: 34px;
  margin-right: 10px;
  margin-left: 20px;
}
.up_demo {
  bottom: 0;
  right: 0;
  height: 231px;
  width: 156px;
}
.new-btn {
  margin: 0 10px 10px 0;
}
.task-list-popover {
  position: absolute;
  right: 11%;
  margin-top: 4px;
}
.task-list-btn {
  color: #2644ca;
  display: flex;
  line-height: 40px;
}
.weChatPush {
  min-height: 60%;
  background-color: #fff;
  position: relative;
  // 内容类型筛选
  .task-type {
    display: inline-block;
    position: absolute;
    left: 17%;
  }
  // 基本信息
  .weChat-news-push {
    background: #fff;
    border-top: 1px dashed #dcdfe6;
    margin-top: 20px;
    .issue-style {
      right: -104px !important;
      color: #91949d;
      cursor: pointer;
      &:hover {
        color: #265cca;
      }
    }
    .disabled-issue-style {
      cursor: no-drop !important;
      color: #91949d !important;
      &:hover:before {
        content: attr(data-tooltip);
        color: red;
        position: absolute;
        left: 0 !important;
        bottom: -10px !important;
        border-radius: 20px;
        text-decoration: none !important;
        line-height: 14px;
        display: inline-block;
        width: 100px;
      }
    }
    .shili {
      position: absolute;
      right: -76px;
      top: 0;
      text-decoration: underline;
      a {
        color: #91949d;
        &:hover {
          color: #265cca;
        }
      }
    }
    .upload-demo {
      width: 80px;
      height: 36px;
      background-color: #91949d;
      border-color: unset;
      position: absolute;
      top: 2px;
    }
    .article-demo {
      right: 90px;
    }
    .article-demo:hover,
    .email-demo:hover {
      transform: scale(1.04, 1.04);
    }
    .email-demo {
      right: 180px;
    }
    .official-demo {
      right: 2px;
      top: -1px;
      background: none;
    }
  }
  //最新目次往期目次
  .settings {
    position: absolute;
    right: 130px;
    .settings-btn {
      top: -2px;
      cursor: pointer;
    }
    .settings-dialog {
      .url {
        display: flex;
        align-items: center;
        justify-content: space-around;
        margin-bottom: 36px;
        .right-info {
          position: relative;
          .info-btn {
            position: absolute;
            right: 0;
          }
        }
      }
    }
  }
  // 新建微网刊文章
  .new-article-form {
    .new-article-img-box {
      position: relative;
      overflow: hidden;
      .new-article-img {
        height: 200px;
        width: 200px;
        border: 1px solid #ddd;
        background: #f4f4f4;
        overflow: hidden;
        cursor: pointer;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      .new-article-upload {
        .el-button {
          height: 200px;
          width: 200px;
          position: absolute;
          top: 0;
          left: 0;
          opacity: 0;
        }
      }
    }
  }
  //微网刊每期文章图片预览
  .preview-img-dialog {
    width: 100%;
    height: 300px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .right-article-demo {
    right: 2px !important;
  }
}
// 微网刊按钮s
.btn-margin-left {
  margin-left: 80px;
}
.btn_fx {
  padding: 14px 26px;
  text-align: center;
}
.btn_fxd {
  text-align: center;
  font-size: 12px;
  color: #ccc;
  margin: 10px 0;
}
.btn_fx2 {
  text-align: center;
}
.is-publish-disabled {
  pointer-events: none;
}
</style>
