<template>
  <div class="w center_all">
    <div class="center_2">
      <ul class="crumbs clearfix">
        <li>邮件推送</li>
        <li>-></li>
        <li>学者管理</li>
      </ul>
      <el-tabs v-model="activeName" @tab-click="handleTabClick">
        <el-tab-pane label="白名单管理" name="1"></el-tab-pane>
        <el-tab-pane label="编委管理" name="2"></el-tab-pane>
      </el-tabs>
      <!-- <h2 class="center_title" style="margin:20px 0">期刊引证情况</h2> -->
      <!-- 饼图 -->
      <h2 class="center_title mb-20">所属机构统计</h2>
      <div class="conte_1">
        <div class="conte_tit_1 btn">
          <!-- <span class="tj_year">指标:</span>
          <el-select v-model="value0" placeholder="请选择" style="width:100px;top:-4px;right:10px;">
            <el-option v-for="item in options0" :key="item.value" :label="item.label" :value="item.value" />
          </el-select> -->
        </div>
        <div id="myChart" :style="{ width: '1138px', height: '490px' }" />
        <!-- 所属机构统计 -->
        <div class="conte_table">
          <el-table :data="statistOrg" style="width: 100%" max-height="450">
            <el-table-column type="index" label="序号" width="90px" align="center" />
            <el-table-column prop="org" label="所在机构" width="250px" align="left">
              <template slot-scope="scope">
                <span class="wcolor">{{ scope.row.org }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="count" label="人数" align="center" sortable />
            <el-table-column prop="percent" label="占比" align="center" sortable />
          </el-table>
        </div>
      </div>
      <!-- 学者分布 -->
      <div class="mb-20">
        <h2 class="center_title mb-20">学者分布</h2>
        <div class="map_table">
          <div id="myChart1" :style="{ width: '620px', height: '470px' }" />
          <div class="center_table">
            <el-table :data="whiteListData" stripe style="width: 100%" max-height="450">
              <el-table-column type="index" label="序号" width="90px" align="center" />
              <el-table-column prop="territory" label="学者分布" align="left">
                <template slot-scope="scope">
                  <span class="wcolor">{{ scope.row.territory }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="count" label="人数" align="left" />
              <el-table-column prop="percent" label="占比" align="left" sortable />
            </el-table>
          </div>
        </div>
      </div>
      <!-- 激活白名单图谱 -->
      <div class="mb-20">
        <h2 class="center_title mb-20">激活白名单传播图谱</h2>
        <div class="map_table">
          <div id="myChart2" :style="{ width: '620px', height: '470px' }" />
          <SimpleTable no-pagination chart-table max-height="450" :tableData="whiteListData" :columnData="whiteColumn" />
        </div>
      </div>
      <div style="position: relative;padding-top:30px">
        <div class="btns1" style="top:-10px">
          <el-button type="text" icon="el-icon-delete" @click="del">删除</el-button>
          <!--          <el-upload class="upload-demo" :show-file-list="false" :action="ImportTemplate" :data="uploadObj" accept=".xlsx" :auto-upload="true" :on-success="handleUploadSuccess">-->
          <el-button type="text" icon="el-icon-dr" @click="importVisible = true,importFun">导入</el-button>
          <!--          </el-upload>-->
          <el-button type="text" icon="el-icon-xz" @click="handleDownload">下载</el-button>
        </div>
      </div>
      <!-- 白名单列表 -->
      <SimpleTable
        hasSelect
        :tableData="authorListPage"
        :columnData="authorColumn"
        style="width:100%"
        :initObj="authorPageObj"
        @onChangePageData="
          pageObj => {
            getAuthorListByPage(pageObj)
          }
        "
        @onChangeSelection="handleSelectionChange"
      />
    </div>
    <el-dialog title="导入" align="left" :close-on-click-modal="false" :visible.sync="importVisible" width="560px" :show-close="true">
      <el-form ref="importForm" :model="importData" :rules="uploadRule">
        <el-form-item label="导入样例：" label-width="95px" align="left">
          <a class="imports_a" @click="downLoadSample">样例文件</a>
        </el-form-item>
        <el-form-item prop="importFileVal" label="导入文件：" align="left" class="importsF">
          <el-input disabled type="text" v-model="inputText" placeholder="选择本地文件"></el-input>
          <el-upload
            ref="importFile"
            class="upload-demo"
            :on-change="handleUploadChange"
            :show-file-list="false"
            :file-list="fileList"
            action="https://www.das.publish.founderss.cn/das-api/vas/author/import/import"
            :data="uploadObj"
            accept=".xlsx"
            :auto-upload="false"
            :on-success="handleUploadSuccess"
          >
            <el-button class="imports" type="primary">选择文件</el-button>
          </el-upload>
        </el-form-item>
        <!--        <el-form-item label="重复记录" label-width="80px" align="left">-->
        <!--          <el-checkbox v-model="checked">覆盖</el-checkbox>-->
        <!--        </el-form-item>-->
        <el-form-item label="说明：" label-width="95px" align="left">
          <ul>
            <li>1.如果文件数据量过大,处理时间会比较长,建议在10000条以内的数据</li>
            <li>2.仅支持.xls、.xlsx格式的文件；请严格按照模版文件中的格式进行数据填充，否则系统无法正常识别</li>
          </ul>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" align="center">
        <el-button type="primary" @click="confirmImport">确 定</el-button>
        <el-button
          @click="
            importVisible = false
            inputText = ''
            fileList = []
            importData = {}
          "
          >取 消</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import SimpleTable from '@/components/SimpleTable'
import chinaCities from '../../../public/js/china-cities'
import china from '../../../public/js/china'
import { mapGetters } from 'vuex'
import { geoData } from '@/utils'
import { GetAuthorListByPage, StatisticsByOrg, ByTerritory, DeleteAuthor, ImportTemplate, downloadList, DownloadTemplate, BWStatisticsByOrg } from '@/api/whiteList'
export default {
  name: 'WhiteList',
  components: {
    SimpleTable
  },
  mounted() {
    this.$nextTick(() => {
      this.whiteListMap()
      this.TerritoryMap()
    })
  },
  computed: {
    ...mapGetters({
      platMagId: 'platMagId',
      platTenantId: 'platTenantId',
      platMagName: 'platMagName',
      magId: 'magId'
    }),
    uploadObj() {
      return {
        magId: this.magId,
        type: this.activeName
      }
    }
  },
  watch: {
    magId() {
      this.getAuthorListByPage()
      this.statisticsByOrg()
      this.byTerritory()
    },
    activeName() {
      this.getAuthorListByPage()
      this.statisticsByOrg()
      this.byTerritory()
    }
  },
  data() {
    return {
      // options0: [
      //   {
      //     value: 1,
      //     label: '所在机构'
      //   },
      //   {
      //     value: 2,
      //     label: '所在机构1'
      //   }
      // ],
      // value0: '所在机构',
      activeName: '1',
      importVisible: false, // 是否显示导入弹框
      importData: {
        importFileVal: ''
      },
      checked: true, // 导入 是否覆盖
      statistOrg: [], //所在机构统计学者数据
      whiteListData: [], // 学者分布数据 激活白名单图谱数据
      authorListPage: [], //列表数据
      authorColumn: [
        {
          title: '作者',
          prop: 'authorName',
          notSortable: true
        },
        {
          title: '机构',
          prop: 'organization'
        },
        {
          title: '邮箱',
          prop: 'email',
          notSortable: true
        },
        {
          title: '类型',
          prop: 'type',
          options: {
            1: '中文',
            2: '英文'
          }
        },
        {
          title: '研究领域',
          prop: 'domain'
        },
        {
          title: '是否激活',
          prop: 'status',
          options: {
            1: '否',
            2: '是'
          }
        }
      ],
      whiteColumn: [
        {
          title: '传播路径',
          prop: 'territory',
          notSortable: true
        },
        {
          title: '人数',
          prop: 'count',
          notSortable: true
        },
        {
          title: '占比',
          prop: 'percent'
        }
      ],
      authorPageObj: {
        page: 0,
        size: 10
      },
      multipleSelection: [],
      ImportTemplate: ImportTemplate,
      inputText: '',
      fileList: [],
      uploadRule: {
        importFileVal: [{ required: true, trigger: 'blur', message: '请上传文件' }]
      }
    }
  },
  mounted() {
    this.getAuthorListByPage()
    this.statisticsByOrg()
    this.byTerritory()
  },
  methods: {
    importFun() {
      importVisible = true
      fileList = []
    },
    handleTabClick() {
      console.log(this.activeName, '000')
    },
    // 所在机构统计学者
    statisticsByOrg() {
      let params = {
        magId: this.magId,
        type: this.activeName,
        page: 0,
        size: 20,
        sort: 'count,desc'
      }
      StatisticsByOrg(params).then(res => {
        if (res && res.data && res.data.status === 0) {
          const { data } = res.data
          const { content = [] } = data
          this.statistOrg = content
          this.drawPie()
        }
      })
    },
    // 所在机构统计学者饼状图
    drawPie() {
      let pieArray = JSON.parse(JSON.stringify(this.statistOrg))
      pieArray.map(item => {
        item.name = item.org
        item.value = item.count
      })
      let myChart = this.$echarts.init(document.getElementById('myChart'))
      // 绘制图表
      myChart.setOption({
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c} ({d}%)'
        },
        series: [
          {
            name: '所在机构',
            type: 'pie',
            color: ['#37a2da', '#32c4e9', '#66e0e3', '#9fe7b9', '#fedb5b', '#ff9f7f', '#fc7293', '#e061ae', '#e690d1', '#e7bcf2'],
            radius: ['40%', '50%'],
            center: ['22%', '55%'],
            label: {
              normal: {
                formatter: '{d}%'
              }
            },
            data: pieArray,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      })
    },
    // 学者分布
    byTerritory() {
      let params = {
        magId: this.magId,
        type: this.activeName,
        page: 0,
        size: 500,
        sort: 'count,desc'
      }
      ByTerritory(params).then(res => {
        if (res && res.data && res.data.status === 0) {
          const { data } = res.data
          this.whiteListData = data.content
          this.TerritoryMap(this.whiteListData)
          this.whiteListMap(this.whiteListData)
        }
      })
    },
    // 学者分布地图
    TerritoryMap(data) {
      let terrmapData = JSON.parse(JSON.stringify(data))
      terrmapData.map(item => {
        if (item.territory) {
          let str = item.territory
          let index = str.lastIndexOf('-')
          item.territory = str.substring(index + 1, str.length - 1)
        }
        item.name = item.territory
        item.value = item.count
      })

      var myChartMap = this.$echarts.init(document.getElementById('myChart1'))
      myChartMap.setOption({
        backgroundColor: '#f5f7fd',
        tooltip: {}, // 鼠标移到图里面的浮动提示框
        dataRange: {
          show: false,
          min: 0,
          max: 1000,
          text: ['High', 'Low'],
          realtime: true,
          calculable: true,
          color: ['orangered', 'yellow', 'lightskyblue']
        },
        geo: {
          // 这个是重点配置区
          map: 'chinaCities', // 表示中国地图
          zoom: 1.2,
          roam: true,
          label: {
            normal: {
              show: false, // 是否显示对应地名
              textStyle: {
                color: 'rgba(0,0,0,0.4)'
              }
            }
          },
          itemStyle: {
            normal: {
              borderColor: 'rgba(0, 0, 0, 0.2)'
            },
            emphasis: {
              areaColor: null,
              shadowOffsetX: 0,
              shadowOffsetY: 0,
              shadowBlur: 20,
              borderWidth: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          }
        },
        series: [
          {
            type: 'scatter',
            coordinateSystem: 'geo' // 对应上方配置
          },
          {
            name: '学者分布', // 浮动框的标题
            type: 'map',
            geoIndex: 0,
            data: terrmapData
          }
        ]
      })
    },
    //激活白名单图谱地图
    whiteListMap(data = []) {
      const myChartMap = this.$echarts.init(document.getElementById('myChart2'))
      // 坐标数据
      const geoCoordMap = geoData
      // 连线特效
      const planePath =
        'path://M1705.06,1318.313v-89.254l-319.9-221.799l0.073-208.063c0.521-84.662-26.629-121.796-63.961-121.491c-37.332-0.305-64.482,36.829-63.961,121.491l0.073,208.063l-319.9,221.799v89.254l330.343-157.288l12.238,241.308l-134.449,92.931l0.531,42.034l175.125-42.917l175.125,42.917l0.531-42.034l-134.449-92.931l12.238-241.308L1705.06,1318.313z'
      // 处理连线坐标
      const convertData = function(data) {
        const res = []
        for (let i = 0; i < data.length; i++) {
          const dataItem = data[i]
          const fromCoord = geoCoordMap[dataItem[0].name]
          const toCoord = geoCoordMap[dataItem[1].name]
          if (fromCoord && toCoord) {
            res.push({
              fromName: dataItem[0].name,
              toName: dataItem[1].name,
              coords: [fromCoord, toCoord]
            })
          }
        }
        return res
      }
      // 数据
      const BJData = data.map(item => {
        if (item.territory) {
          let str = item.territory
          let index = str.lastIndexOf('-')
          item.territory = str.substring(index + 1, str.length - 1)
        }
        item.name = item.territory
        const tempVal = [{ name: '北京' }]
        const targetVal = { name: item.territory, value: item.count }
        tempVal.push(targetVal)
        return tempVal
      })
      const color = ['#a6c84c', '#ffa022', '#46bee9']
      const series = []
      const array = [['北京', BJData]]
      array.forEach(function(item, i) {
        series.push(
          {
            type: 'lines',
            zlevel: 1,
            effect: {
              show: true,
              period: 6,
              trailLength: 0.7,
              color: '#fff',
              symbolSize: 3
            },
            lineStyle: {
              normal: {
                color: color[i],
                width: 0,
                curveness: 0.2
              }
            },
            data: convertData(item[1])
          },
          {
            type: 'lines',
            zlevel: 2,
            symbol: ['none', 'arrow'],
            symbolSize: 10,
            effect: {
              show: false,
              period: 6,
              trailLength: 0,
              symbol: planePath,
              symbolSize: 15
            },
            lineStyle: {
              color: color[i],
              width: 1,
              opacity: 0.6,
              curveness: 0.2
            },
            data: convertData(item[1])
          },
          {
            type: 'effectScatter',
            coordinateSystem: 'geo',
            zlevel: 3,
            rippleEffect: {
              brushType: 'stroke'
            },
            label: {
              show: true,
              position: 'right',
              formatter: '{b}'
            },
            symbolSize: function(val) {
              return val[2] / 8
            },
            itemStyle: {
              color: color[i]
            },
            data: item[1].map(function(dataItem) {
              if (geoCoordMap[dataItem[1].name] && [dataItem[1].value]) {
                return {
                  name: dataItem[1].name,
                  value: geoCoordMap[dataItem[1].name].concat([dataItem[1].value])
                }
              } else {
                return false
              }
            })
          }
        )
      })
      myChartMap.setOption({
        // 新建一个地理坐标系 geo ，
        geo: {
          map: 'china', // 地图类型为中国地图,要是海外那就是world,要是省市区：例如beijing、shanghai
          zoom: 1.2,
          itemStyle: {
            // 定义样式
            normal: {
              // 普通状态下的样式
              areaColor: '#6699CC',
              borderColor: '#fff'
            },
            emphasis: {
              // 高亮状态下的样式
              areaColor: '#e9fbf1'
            }
          }
        },
        // hover显示目标数据
        tooltip: {
          trigger: 'item',
          formatter: function(params) {
            let res
            if (params.data.value) {
              res = '<p>人数' + params.data.value[2] + '</p>'
            }

            return res
          },
          // tooltip的trigger的值可以有'item'、'axis'。
          // 'item':数据项图形触发，主要在散点图，饼图等无类目轴的图表中使用。
          // 'axis':坐标轴触发，主要在柱状图，折线图等会使用类目轴的图表中使用
          textStyle: {
            align: 'left'
          }
        },
        // 图表背景色
        backgroundColor: '#2644ca',
        // 标志颜色
        color: 'red',
        // 新建散点图series
        series: series,
        // // 添加视觉映射组件
        visualMap: {
          type: 'continuous', // 连续型
          min: 0, // 值域最小值，必须参数
          max: 100, // 值域最大值，必须参数
          calculable: true, // 是否启用值域漫游
          inRange: {
            color: ['yellow', 'red']
            // 指定数值从低到高时的颜色变化
          },
          itemWidth: 10,
          itemHeight: 92,
          textStyle: {
            color: '#fff' // 值域控件的文本颜色
          }
        }
      })
    },
    //白名单列表数据
    getAuthorListByPage(pageParams = {}) {
      const { page = 1, size = 10 } = pageParams
      let params = {
        magId: this.magId,
        type: this.activeName,
        page: page - 1,
        size: size
      }
      GetAuthorListByPage(params).then(res => {
        if (res && res.data && res.data.status === 0) {
          const { data } = res.data
          const { content = [], totalElements = 0 } = data
          this.authorPageObj = {
            page,
            size,
            total: totalElements
          }
          this.authorListPage = content
        }
      })
    },
    // 列表选中数据改变
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    // 删除
    del() {
      if (this.multipleSelection && this.multipleSelection.length > 0) {
        this.$confirm('将删除选中信息，确认删除？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          cancelButtonClass: 'btn-custom-cancel',
          type: 'warning'
        })
          .then(() => {
            const delIds = this.multipleSelection.map(item => {
              return item.id
            })
            DeleteAuthor({
              ids: delIds.join(',')
            }).then(res => {
              if (res && res.data && res.data.status === 0) {
                this.$message({
                  type: 'success',
                  message: '删除成功',
                  showClose: true
                })
                this.getAuthorListByPage()
              }
            })
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消删除',
              showClose: true
            })
          })
      } else {
        this.$message({
          type: 'info',
          message: '请选择要删除的信息',
          showClose: true
        })
      }
    },
    // 导入 弹框 确认按钮
    confirmImport() {
      this.$refs.importForm.validate(valid => {
        if (valid) {
          this.importVisible = false
          this.$confirm(`是否导入?`, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            cancelButtonClass: 'btn-custom-cancel',
            type: 'warning'
          })
            .then(() => {
              this.$refs.importFile.submit()
            })
            .catch(() => {
              this.$message({
                type: 'info',
                message: '已取消导入'
              })
            })
        }
      })
    },
    // 上传状态改变
    handleUploadChange(file, fileList) {
      const { status } = file
      if (status === 'success') {
        return
      }
      const { raw } = file
      const { type = '' } = raw
      if (type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        this.$message.warning('上传文件格式错误')
        return false
      }
      this.fileList = [file]
      this.$refs.importFile.clearFiles()
      const { name = '' } = file
      this.inputText = name
      this.$set(this.importData, 'importFileVal', name)
      this.$refs.importForm.clearValidate()
    },
    // 导入模板成功回调
    handleUploadSuccess(res, file) {
      if (res && res.status === 0) {
        this.getAuthorListByPage()
        this.inputText = ''
        this.fileList = []
        this.importData = {}
      } else {
        this.inputText = ''
        this.fileList = []
        this.importData = {}
        const { message = '' } = res
        this.$message.error(message)
      }
    },
    // 弹框 下载样例文件
    downLoadSample() {
      window.open(DownloadTemplate, '_self')
    },
    // 下载列表
    handleDownload() {
      window.open(`${downloadList}?magId=${this.magId}&type=${this.activeName}`, '_self')
      return
    }
  }
}
</script>
<style scoped lang="scss">
.conte_1 {
  background-color: #f6f7fc;
  position: relative;
  margin-bottom: 20px;
}
.conte_tit_1 {
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 200;
}
.tj_year {
  color: #999;
  font-size: 14px;
  position: relative;
  top: -3px;
  margin-right: 10px;
}
.conte_table {
  width: 50%;
  position: absolute;
  top: 20px;
  right: 20px;
}
.abstract {
  text-align: left;
}
.title_z {
  font-size: 20px;
  color: #333333;
  line-height: 30px;
  margin-top: 10px;
}
.title_e {
  font: 14px;
  color: #999;
  line-height: 20px;
  margin-bottom: 10px;
}
.abstract_t {
  font-size: 14px;
  line-height: 24px;
  color: #666;
}
.more {
  margin-top: 10px;
  margin-bottom: 10px;
}
.more li {
  line-height: 24px;
  font-size: 14px;
  color: #999;
}
.more li span {
  color: #666;
}
.sort {
  text-align: left;
}
.sort > span {
  height: 32px;
  line-height: 12px;
  box-sizing: border-box;
  font-size: 14px;
  padding: 9px 10px 9px 0;
  color: #999;
}
.conter_list {
  text-align: left;
}
.conter_list > li {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  padding: 20px 0;
}
.conter_list_title {
  font-size: 16px;
  color: #333;
  line-height: 24px;
}
.conter_list_more {
  color: #999;
  font-size: 14px;
  line-height: 24px;
}
.conter_list_more li {
  float: left;
  margin-right: 14px;
}
.conter_list_abstract {
  margin-top: 10px;
  color: #666;
  line-height: 24px;
  font-size: 14px;
}
.conter_list_keywords {
  margin-top: 10px;
  font-size: 14px;
  color: #999;
  line-height: 18px;
}
.btns1 {
  position: absolute;
  top: -40px;
  right: 0;
}
.center_table {
  position: absolute;
  top: 0px;
  right: -10px;
  padding: 10px;
  width: 510px;
  height: 450px;
}
.map_table {
  position: relative;
  background: #f5f7fd;
}
.postion {
  position: relative;
}
.setChartData {
  position: absolute;
  top: 70px;
  left: 50%;
  margin-left: -78px;
  z-index: 100;
}
.textOver {
  overflow: hidden;
  white-space: nowrap;
  word-break: break-all;
}
.upload-demo {
  display: inline-block;
}
.importsF {
  position: relative;
}
.importsF .el-input {
  width: 81%;
}
.imports {
  position: absolute;
  top: 2px;
  right: 5px;
  height: 28px;
  line-height: 8px;
  width: auto;
}
.imports_a {
  color: #265cca;
  cursor: pointer;
}
.btns1 .el-button--text {
  text-indent: 16px;
}
.el-dialog__wrapper {
  background: rgba(0, 0, 0, 0.3) !important;
}
</style>
