<!-- scholarDataEvaluation -->
<template>
  <div class="w center_all scholarDataEvaluation">
    <div class="center_2">
      <div class="header">
        <ul class="crumbs clearfix">
          <li>邮件推送</li>
          <li>-></li>
          <li>年度报告</li>
        </ul>
      </div>
      <div class="content-box">
        <div class="content-box-header">
          <div class="date-range mb-20">
            选择年度：
            <el-date-picker value-format="yyyy" v-model="dateValue" type="year" placeholder="选择年度"
              :picker-options="startTimeOptions" @focus="clickStartTime">
            </el-date-picker>
          </div>
          <div :class="isDisabled ? 'download-btn-disabled' : 'download-btn'" @click="handleDownloadOrGenerate">
            <img :src="isDisabled ? downloadReportDisabledImg : downloadReportImg" alt="" />
            <span>{{ buttonText }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  generateAnnualReport, getAnnualReportStatus
} from '@/api/emailPush'
export default {
  name: 'scholarDataEvaluation',
  data() {
    return {
      downloadReportImg: require('@/assets/img/download-report.png'),
      downloadReportDisabledImg: require('@/assets/img/download-report-disabled.png'),
      dateValue: [],
      startTimeOptions: {},
      isDisabled: false,
      buttonText: '生成报告',
      reportStatusInterval: null,
      downloadUrl: '',
      curYear: 2025
    }
  },
  props: {},
  computed: {
    ...mapGetters({
      magId: 'magId'
    })
  },
  mounted() {
    var now = new Date()
    var year = now.getFullYear() // 得到年份
    this.dateValue = year.toString()
  },
  watch: {
    dateValue: {
      handler(newVal) {
        clearInterval(this.reportStatusInterval)
        if (newVal.length > 0) {
          this.startCheckingStatus()
        }
      },
      deep: true
    }
  },
  methods: {
    clickStartTime() {
      this.startTimeOptions = {
        disabledDate: (time) => {
          if (time.getTime() > Date.now()) {
            return true
          }
        }
      }
    },
    async checkReportStatus() {
      try {
        const res = await getAnnualReportStatus({ magId: this.magId, year: this.dateValue })
        console.log('checkReportStatus', res)
        if (res && res.data && res.data.data && res.data.status === 0 && res.data.data.status === 1) {
          console.log('checkReportStatus', "下载报告")
          this.buttonText = '下载报告'
          this.isDisabled = false
          this.downloadUrl = res.data.data.url
          clearInterval(this.reportStatusInterval)
        } else if (res && res.data && res.data.data && res.data.status === 0 && res.data.data.status === 0){
          this.buttonText = '生成中...'
          this.isDisabled = true
          console.log('checkReportStatus', "生成中...")
        } else {
          this.buttonText = '生成报告'
          this.isDisabled = false
          clearInterval(this.reportStatusInterval)
          console.log('checkReportStatus', "生成报告")
        }
      } catch (error) {
        console.error('查询报告状态失败', error)
      }
    },
    startCheckingStatus() {
      this.checkReportStatus()
      this.reportStatusInterval = setInterval(() => {
        this.checkReportStatus()
      }, 3000)
    },
    handleDownloadOrGenerate() {
      if (!this.isDisabled) {
        if (this.buttonText === '生成报告') {
          this.generateReport()
        } else {
          window.open(this.downloadUrl, '_blank')
        }
      }

    },
    async generateReport() {
      try {
        await generateAnnualReport({ magId: this.magId, year: this.dateValue })
        this.buttonText = '生成中...'
        this.isDisabled = true
        this.startCheckingStatus()
      } catch (error) {
        console.error('生成报告失败', error)
      }
    }
  }
}
</script>
<style lang="scss">
.scholarDataEvaluation {
  .content-box {
    .flex-content {
      .conter_3 {
        .conter_page {
          display: block;
          text-align: left;

          .jumpPage {
            float: right;
          }
        }

        .el-table {
          min-height: 440px;
        }
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.scholarDataEvaluation {
  .content-box {
    text-align: left;

    .content-box-header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .download-btn {
        display: block;
        font-size: 18px;
        font-weight: bold;
        cursor: pointer;
        color: #4054af;
        display: flex;
        line-height: 27px;
        justify-content: flex-end;
        margin: 30px;

        img {
          width: 30px;
          height: 30px;
          margin-right: 6px;
        }
      }

      .download-btn-disabled {
        color: #c0c4cc;
        cursor: no-drop;
      }
    }

    .explanation {
      font-size: 16px;

      .bold {
        font-weight: bold;
      }
    }

    .flex-content {
      display: flex;
      justify-content: space-between;

      .flex-content-item {
        width: 49%;
        text-align: center;

        h3 {
          margin: 10px 0;
        }
      }
    }
  }
}
</style>
