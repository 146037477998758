<!-- previewPushResultsDetails -->
<template>
  <div class="previewPushResultsDetails w center_all">
    <div class="center_2">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/previewPushResultsList' }">推送结果一览</el-breadcrumb-item>
        <el-breadcrumb-item>详情</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="header">
        <div class="title">{{ $route.query.topic }}</div>
        <el-button @click="exportExcel">导出</el-button>
      </div>
      <div class="table-list">
        <SimpleTable
          noSerial
          btn1Text="详情"
          :tableData="tableData"
          :columnData="tableColumn"
          :initObj="tablePageObj"
          @onChangeSort="
            (prop, order) => {
              handleChangeSort(prop, order)
            }
          "
          @onChangePageData="
            (pageObj) => {
              getTableList(pageObj)
            }
          "
        ></SimpleTable>
      </div>
    </div>
  </div>
</template>

<script>
import SimpleTable from '@/components/SimpleTable'
import { GetHistoryPushSetList, ExportHistoryPushSetList } from '@/api/roleManagement'

export default {
  name: 'previewPushResultsDetails',
  data() {
    return {
      tableData: [],
      tableColumn: [
        {
          title: '学者来源',
          prop: 'sourceName',
          notSortable: true
        },
        {
          title: '论文范围',
          prop: 'rangeName',
          notSortable: true
        },
        {
          title: '学者行为',
          prop: 'behaviorName',
          notSortable: true
        },
        {
          title: '推送数量',
          prop: 'count',
          width: '100px'
        },
        {
          title: '推送成功数量',
          prop: 'successCount',
          width: '125px'
        },
        {
          title: '总打开数',
          prop: 'openCount',
          width: '100px'
        },
        {
          title: '唯一打开数',
          prop: 'openAuthorCount',
          width: '110px'
        },
        {
          title: '打开率',
          prop: 'openAuthorPercentStr',
          width: '90px'
        },
        {
          title: '点击数',
          prop: 'readCount',
          width: '90px'
        },
        {
          title: '唯一点击数',
          prop: 'readAuthorCount',
          width: '110px'
        },
        {
          title: '点击率',
          prop: 'readAuthorPercentStr',
          width: '90px'
        }
      ],
      tablePageObj: {
        page: 1,
        size: 100,
        sort: ''
      }
    }
  },
  props: {},
  components: { SimpleTable },
  computed: {},
  mounted() {
    this.getTableList()
  },
  methods: {
    getTableList(pageParams = {}) {
      let { page = 1, size = 100 } = pageParams
      let params = {
        historyId: this.$route.query.id,
        page: page - 1,
        size,
        sort: this.tablePageObj.sort
      }
      GetHistoryPushSetList(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          const { data } = res.data
          this.tableData = data
          this.tablePageObj = {
            ...this.tablePageObj,
            page,
            size,
            total: data.totalElements
          }
        } else {
          const { message = '' } = res.data
          this.$message.error(message)
        }
      })
    },
    // 导出列表
    exportExcel() {
      window.open(`${ExportHistoryPushSetList}?historyId=${this.$route.query.id}`, '_self')
    },
    handleChangeSort(prop, order) {
      let sortValue = ''
      if (order && order !== '') {
        if (order === 'ascending') {
          sortValue = prop + ',asc'
        } else {
          sortValue = prop + ',desc'
        }
      }
      this.tablePageObj.sort = sortValue
      this.getTableList()
    }
  }
}
</script>
<style lang="scss">
.previewPushResultsDetails {
  .center_2 {
    .el-breadcrumb__separator,
    .el-breadcrumb__item {
      color: #000;
      .el-breadcrumb__inner {
        color: #000;
        font-weight: bold;
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.previewPushResultsDetails {
  .center_2 {
    min-height: 400px;
    border-radius: 8px;
    padding: 40px;
    .header {
      margin-top: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .table-list {
      margin-top: 20px;
    }
  }
}
</style>
