<!-- taskManagement -->
<template>
  <div class="taskManagement w center_all">
    <div class="center_2">
      <ul class="crumbs clearfix">
        <li>邮件推送</li>
        <li>-></li>
        <li>任务管理</li>
      </ul>
      <!-- 新建任务按钮 -->
      <el-button class="new-btn" size="small" round icon="el-icon-plus" @click="newTask">新建</el-button>
      <!-- 新建任务弹框 -->
      <el-dialog :show-close="true" :visible.sync="newTaskDialogVisible" width="600px" title="新建任务">
        <el-form ref="newTaskForm" :model="newTaskForm"
          :rules="newTaskForm.type == 2 ? newTopicTaskRules : newWholePeriodTaskRules" status-icon label-width="120px"
          class="demo-ruleForm">
          <el-form-item label="内容类型：" prop="type" size="zdy" style="width:450px">
            <el-select v-model="newTaskForm.type" placeholder="请选择" style="width:325px">
              <el-option label="整期任务" value="1"></el-option>
              <el-option label="专题任务" value="2"></el-option>
              <el-option label="通知任务" value="3"></el-option>
              <el-option label="单篇任务" value="4"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="邮件主题：" size="zdy" prop="topic" style="width:450px">
            <el-input id="topic" v-model="newTaskForm.topic" />
          </el-form-item>
          <el-form-item v-if="newTaskForm.type == 2" label="年份：" prop="year" style="width:450px">
            <el-date-picker class="year-picker" value-format="yyyy" v-model="newTaskForm.year" type="year"
              placeholder="请选择"> </el-date-picker>
          </el-form-item>
          <el-form-item v-if="newTaskForm.type == 2" label="刊期：" size="zdy" prop="issue" style="width:450px">
            <el-input v-model="newTaskForm.issue" />
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="newTaskDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="newTaskBtn('new')">确 定</el-button>
        </span>
      </el-dialog>
      <!-- 根据内容类型查找 -->
      <div class="task-type" v-if="haveTaskList">
        内容类型：
        <el-select v-model="taskType" placeholder="请选择" @change="taskTypeChange">
          <el-option v-for="item in taskTypeOptions" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <!-- 任务列表 -->
      <taskList ref="taskList" v-model="taskListData" @getCurrentTask="getCurrentTask" :taskType="taskType"></taskList>
      <!-- 按钮区域 -->
      <div class="btns" style="display:flex">
        <el-button @click="toContentConfigure">配置内容</el-button>
        <el-button @click="analysisDialog = true">本期发文分析</el-button>
        <el-button @click="chooseScholarMag">选择学者</el-button>
        <el-button :disabled="scholarAnalysisDisabled" @click="scholarAnalysis">查看学者</el-button>
        <el-popover class="previewEmail" popper-class="previewEmail-popper" placement="top-start" width="200"
          trigger="click">
          <div class="previewEmail-list">
            <div class="previewEmail-list-item" @click="previewEmail('all')" v-if="tryPushCheckList.length > 1">
              全部
            </div>
            <div class="previewEmail-list-item" v-for="(item, index) in tryPushCheckList" :key="index"
              @click="previewEmail('item', item)">
              {{ item.name }}
            </div>
          </div>
          <el-button slot="reference" :disabled="dialogShow" @click="getTemplate(1)">预览</el-button>
        </el-popover>
        <el-button :disabled="dialogShow" @click=";[(dialogFormVisible = true), getTemplate(1)]">邮件试推送</el-button>
        <el-button :disabled="dialogShow" @click="officialPush">正式推送</el-button>
      </div>
      <!-- 查看学者弹框 -->
      <el-dialog title="查看学者" :visible.sync="checkScholarDialog" width="400px" :show-close="true"
        class="check-scholar-dialog">
        <h2>{{ currentTask ? currentTask.topic : '--' }}</h2>
        <div class="num-btn">
          <div class="left">
            <div class="num">
              {{ checkScholarText }}数: <span v-loading="userAllNumberLoading">{{ userAllNumber || '--' }}</span>
            </div>
            <div class="num">
              可推送学者数: <span v-loading="userRemainNumberLoading">{{ userRemainNumber || '--' }}</span>
            </div>
            <div class="num" v-if="currentTask.pushType == 8">
              预测打开率: <span>{{ currentTask.forecastOpenRate ? (currentTask.forecastOpenRate * 100).toFixed(2) + '%' :
                '--' }}</span>
            </div>
          </div>
        </div>
        <div class="left">
          <el-button @click="checkPushMagHistoryAuthorTime('reflesh')" type="small"
            :disabled="currentTask && currentTask.status == 1">刷新学者</el-button>
          <el-button @click="applyExportPushHistoryImportAuthorExcel"
            :disabled="exportExcelStatus == 2">申请导出学者</el-button>
          <span class="reflesh-author" @click="handleDownloadPushUser"
            :class="downloadBtnDisabled ? 'btn-disabled' : ''">下载学者</span>
        </div>
        <div class="time">刷新学者时间：{{ refleshAuthorTime }}</div>
        <el-button class="scholar-analysis-btn" :disabled="toScholarBtnDisabled" type="small"
          @click="toScholarAnalysis">学者分布详情</el-button>&nbsp;
        <el-button class="scholar-analysis-btn" :disabled="toScholarBtnDisabled" type="small"
          @click="toScholarAnalysisReport">学者分析报告</el-button>
      </el-dialog>
      <!--  试推送弹框  -->
      <el-dialog title="试推送" :visible.sync="dialogFormVisible" width="600px" :show-close="true">
        <el-form :model="tryPushData" :rules="tryPushRules">
          <el-form-item label="推送标题：" label-width="120px" align="left">{{ currentTask.topic }}</el-form-item>
          <el-form-item label="试推送邮箱：" prop="email" label-width="120px">
            <el-input v-model="tryPushData.email" autocomplete="off"></el-input>
          </el-form-item>
          <el-checkbox-group v-model="tryPushCheckListId" class="tryPush-list">
            <div class="tryPush-list-item" v-for="(item, index) in tryPushCheckList" :key="index">
              <el-checkbox :label="item.id">{{ item.name }}</el-checkbox>
            </div>
          </el-checkbox-group>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="confirm">确 定</el-button>
          <el-button @click="dialogFormVisible = false">取 消</el-button>
        </div>
      </el-dialog>
      <!-- 本期参考文献分析弹框 -->
      <el-dialog title="本期发文分析" :visible.sync="analysisDialog" width="400px" :show-close="true">
        <el-button type="small" @click="commitAnalysis">提交分析</el-button>
        <el-button type="small" @click="downloadAnalysis">下载分析结果</el-button>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import taskList from './components/taskList'
import { mapGetters } from 'vuex'
import {
  NewEditTaskInfo,
  GetSendMemberSize,
  CheckPushMagHistoryAuthorTime,
  PreparePushMagHistoryAuthorMessage,
  exportSendMember,
  PreparePushMagHistoryAuthor,
  GetDownloadAuthorStatus,
  ApplyDownloadAuthor,
  GetTemplate,
  ReferenceArticleAnalysis,
  GetApplyExportPushHistoryImportAuthorExcel,
  ExportAnalysisFile
} from '@/api/emailPush'
import { GetTaskInfo } from '@/api/publishResults'
import { TaskTrySend, apiGetTaskArticles } from '@/api/selfPush'
import { TimestampToTime } from '@/assets/js/util'
export default {
  name: 'emailTaskManagement',
  data() {
    return {
      newTaskDialogVisible: false,
      newTaskForm: {
        topic: '',
        type: '1',
        year: '',
        issue: ''
      },
      newWholePeriodTaskRules: {
        type: [{ message: '请选择内容类型', trigger: 'blur', required: true }],
        topic: [{ message: '请填写邮件主题', trigger: 'blur', required: true }]
      },
      newTopicTaskRules: {
        type: [{ message: '请选择内容类型', trigger: 'blur', required: true }],
        topic: [{ message: '请填写邮件主题', trigger: 'blur', required: true }],
        year: [{ message: '请选择年份', trigger: 'change', required: true }],
        issue: [{ message: '请填写刊期', trigger: 'blur', required: true }]
      },
      taskType: '',
      taskTypeOptions: [
        { label: '全部', value: '' },
        { label: '整期任务', value: 1 },
        { label: '专题任务', value: 2 },
        { label: '通知任务', value: 3 },
        { label: '单篇任务', value: 4 }
      ],
      taskListData: [], // 任务列表数据
      currentTask: '',
      tryPushData: {
        email: ''
      },
      dialogFormVisible: false, // 试推送 弹框
      tryPushRules: {
        email: [{ message: '请填写试推送邮箱地址', trigger: 'blur', required: true }]
      },
      ruleForm: {
        topic: '',
        list: ''
      },
      checkScholarDialog: false,
      userAllNumber: '',
      userRemainNumber: '',
      checkScholarStatus: '',
      userAllNumberLoading: true,
      userRemainNumberLoading: true,
      pushAuthorList: [
        { id: 1, name: '刊维度学者' },
        { id: 4, name: '刊维度激活学者' },
        { id: 5, name: '期维度学者' },
        { id: 6, name: '期维度激活学者' },
        { id: 7, name: '本期导入学者' },
        { id: 10, name: '自定义推送学者' },
        { id: 8, name: '智能推荐学者' }
      ],
      issueTableData: [],
      downloadBtnDisabled: false,
      downLoadAuthorData: '',
      historyTemplateList: [],
      tryPushCheckListId: [],
      tryPushCheckList: [],
      analysisDialog: false,
      exportExcelStatus: null // 导出学者是否禁用
    }
  },
  props: {},
  components: {
    taskList
  },
  computed: {
    ...mapGetters({
      magId: 'magId'
    }),
    haveTaskList() {
      return this.taskType === '' ? this.taskListData && this.taskListData.length > 0 : true
    },
    scholarAnalysisDisabled() {
      return !(this.currentTask && this.currentTask.pushType)
    },
    dialogShow() {
      let dialogShow = false
      if (this.currentTask.type === 3) {
        dialogShow = false // 通知类型不用选文章
      } else if (!(this.issueTableData.length > 0 && this.historyTemplateList.length > 0)) {
        dialogShow = true
      }
      return dialogShow
    },
    checkScholarText() {
      let text = ''
      if (!this.scholarAnalysisDisabled) {
        this.pushAuthorList.map((item) => {
          if (item.id === this.currentTask.pushType) {
            text = item.name
          }
        })
      }
      return text
    },
    refleshAuthorTime() {
      return this.currentTask ? TimestampToTime(this.currentTask.sendEmailUuid) : ''
    },
    toScholarBtnDisabled() {
      return this.checkScholarStatus !== 2
    }
  },
  mounted() { },
  methods: {
    //新建任务按钮
    newTask() {
      this.newTaskDialogVisible = true
      this.$nextTick(() => {
        this.$refs.newTaskForm.resetFields()
      })
    },
    //新建编辑任务确定
    newTaskBtn(type) {
      let form = type === 'new' ? 'newTaskForm' : type === 'edit' ? 'ruleForm' : ''
      this.$refs[form].validate((valid) => {
        if (valid) {
          let params = {}
          if (type === 'new') {
            params = {
              magId: this.magId,
              topic: this.newTaskForm.topic,
              operatorName: '方正运营',
              year: this.newTaskForm.year,
              issue: this.newTaskForm.issue,
              type: this.newTaskForm.type
            }
          } else if (type === 'edit') {
            params = {
              magId: this.magId,
              topic: this.currentTask.topic,
              operatorName: '方正运营',
              taskId: this.taskId
            }
          }
          NewEditTaskInfo(params).then((res) => {
            if (res && res.data && res.data.status === 0) {
              this.$message({
                type: 'success',
                message: type === 'new' ? '新建成功' : type === 'edit' ? '修改成功' : ''
              })
              this.newTaskDialogVisible = false
              this.$nextTick(() => {
                this.$refs.taskList.getTaskList(this.$refs.taskList.taskListPageObj, type, this.currentTask)
              })
            } else {
              this.$message({
                type: 'error',
                message: res.data.message
              })
            }
          })
        }
      })
    },
    // 内容类型切换
    taskTypeChange() {
      this.$nextTick(() => {
        this.$refs.taskList.getTaskList()
      })
    },
    //获取当前任务
    getCurrentTask(id, item) {
      this.taskId = id
      this.getTaskInfoData(id)
      this.getTaskArticles()
      this.getTemplate()
      this.getTemplate(1)
    },
    // 获取选中的task的详情数据
    getTaskInfoData(id, type) {
      const params = {
        taskId: id
      }
      GetTaskInfo(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          const { data } = res.data
          this.currentTask = data
          sessionStorage.setItem('emailCurrentTask', JSON.stringify(this.currentTask))
          if (this.currentTask) {
            if (type === 'officialPush') {
              this.$nextTick(() => {
                this.$refs.taskList.pushAgain(this.currentTask, this.currentTask.pushType)
              })
            } else {
              // this.ruleForm.topic = this.currentTask.topic
              // this.ruleForm.templateId = this.currentTask.templateId ? this.currentTask.templateId : ''
              // this.ruleForm.pushType = this.currentTask.pushType ? this.currentTask.pushType : ''
            }
          }
        }
      })
    },
    // 获取当前任务文章列表
    getTaskArticles(type, item) {
      let params = {
        taskId: this.taskId
      }
      apiGetTaskArticles(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          this.issueTableData = res.data.data
        } else {
          const { message = '' } = res.data
          this.$message.error(message)
        }
      })
    },
    // 查看学者
    scholarAnalysis() {
      this.userAllNumber = ''
      this.userRemainNumber = ''
      this.userAllNumberLoading = true
      this.userRemainNumberLoading = true
      // this.applyDownloadAuthor()
      this.getSendMemberSize(true)
      this.getSendMemberSize()
      this.checkPushMagHistoryAuthorTime()
      this.getDownloadAuthorStatus()
    },
    //邮件推送-查看学者-下载学者状态查询
    getDownloadAuthorStatus() {
      let params = {
        key: 'applyExportPushHistoryImportAuthorExcel',
        value: this.taskId + '_' + this.currentTask.sendEmailUuid
      }
      GetDownloadAuthorStatus(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          const { data } = res.data
          this.downLoadAuthorData = data
          if (data && data.status === 0) {
            this.downloadBtnDisabled = true

          } else {
            this.downloadBtnDisabled = false
          }
          this.exportExcelStatus = data.status
          console.log(this.exportExcelStatus)
          if (this.exportExcelStatus == 3) {
            this.$message.error('导出学者失败！')
          }
        } else {
          this.downloadBtnDisabled = true
          const { message = '' } = res.data
          this.$message.error(message)
        }
      })
    },
    applyDownloadAuthor() {
      let params = {
        historyId: this.taskId
      }
      ApplyDownloadAuthor(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
        } else {
          const { message = '' } = res.data
          this.$message.error(message)
        }
      })
    },
    // 查看学者分析状态
    checkPushMagHistoryAuthorTime(type) {
      let params = {
        id: this.taskId
      }
      CheckPushMagHistoryAuthorTime(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          const { data } = res.data
          // 0 表示需要初始化  1 表示正在初始化 2表示初始化完成
          this.checkScholarStatus = data
          if (data == 2) {
            if (type === 'reflesh') {
              this.preparePushMagHistoryAuthor()
              this.checkScholarDialog = false
            } else {
              if (this.currentTask.sendEmailUuid) {
                let params1 = {
                  uuid: this.currentTask.sendEmailUuid
                }
                PreparePushMagHistoryAuthorMessage(params1).then((res) => {
                  this.$message({
                    type: 'info',
                    message: res.data
                  })
                })
              }
              this.checkScholarDialog = true
              sessionStorage.setItem('emailCurrentTask', JSON.stringify(this.currentTask))
            }
          } else if (data == 1) {
            this.$message({
              type: 'info',
              message: '学者数据正在分析，请过几分钟后查看'
            })
            if (type === 'reflesh') {
              this.getSendMemberSize(true)
              this.getSendMemberSize()
            }
          } else {
            if (type === 'reflesh') {
              this.preparePushMagHistoryAuthor()
              this.checkScholarDialog = false
            } else {
              this.checkScholarDialog = true
              this.$message({
                type: 'info',
                message: '学者数据需要分析,请点击刷新学者按钮进行数据分析!'
              })
            }
          }
        } else {
          this.$message({
            type: 'error',
            message: res.data.message
          })
        }
      })
    },
    // 初始化学者
    preparePushMagHistoryAuthor() {
      let params = {
        id: this.taskId,
        isReset: true
      }
      PreparePushMagHistoryAuthor(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          this.$message({
            type: 'success',
            message: '学者数据开始分析!请过几分钟后查看'
          })
          this.userAllNumber = ''
          this.userRemainNumber = ''
          this.checkScholarStatus = ''
          this.getTaskInfoData(this.taskId)
        } else {
          const { message = '' } = res.data
          this.$message.error(message)
        }
      })
    },
    // 申请导出学者
    applyExportPushHistoryImportAuthorExcel() {
      let params = {
        historyId: this.taskId,
      }
      GetApplyExportPushHistoryImportAuthorExcel(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          let exportStatus = res.data.data.status
          if (exportStatus == 3) {
            this.$message.error('导出学者失败！')
            this.downloadBtnDisabled = true
          } else if (exportStatus == 2) {
            this.getDownloadAuthorStatus()
            this.$message.success('导出学者成功！')
          } else {
            this.downloadBtnDisabled = true
            this.$message.success('导出学者正在进行中,请稍等！')
          }
        } else {
          const { message = '' } = res.data
          this.$message.error(message)
        }
      })
    },
    // 跳转至学者分析页面
    toScholarAnalysis() {
      if (this.toScholarBtnDisabled) return
      let routeData = this.$router.resolve({
        name: 'scholarAnalysis'
      })
      window.open(routeData.href, '_blank')
    },
    // 跳转至学者分析页面
    toScholarAnalysisReport() {
      if (this.toScholarBtnDisabled) return
      let routeData = this.$router.resolve({
        name: 'scholarAnalysisReport'
      })
      window.open(routeData.href, '_blank')
    },
    //下载推送学者
    handleDownloadPushUser() {
      if (this.downloadBtnDisabled) {
        return
      }
      window.open(`${exportSendMember}?recordId=${this.downLoadAuthorData.recordId}&fileName=${this.currentTask.topic}学者名单.xlsx`, '_self')
    },
    // 获取推送学者人数
    getSendMemberSize(type) {
      let params = {
        id: this.currentTask.id
      }
      if (type) {
        params = { ...params, isAll: type }
      }
      GetSendMemberSize(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          const { data } = res.data
          if (type) {
            this.userAllNumber = this.checkScholarStatus === 1 ? '' : data
            this.userAllNumberLoading = false
          } else {
            this.userRemainNumber = this.checkScholarStatus === 1 ? '' : data
            this.userRemainNumberLoading = false
          }
        }
      })
    },
    // 预览邮件
    previewEmail(type, item) {
      if (type === 'all') {
        this.tryPushCheckList.map((it) => {
          this.previewEmailJump(it.id)
        })
      }
      if (type === 'item') {
        this.previewEmailJump(item.id)
      }
    },
    previewEmailJump(id) {
      let routeData = this.$router.resolve({
        path: '/emailPreviewHtml',
        query: {
          taskId: this.taskId,
          pushHistoryTemplateId: id
        }
      })
      window.open(routeData.href, '_blank')
      // let url =
      //   window.location.origin +
      //   '/das-api/vas/push/task/getTemplateHtml5Info?taskId=' +
      //   this.taskId +
      //   '&pushHistoryTemplateId=' +
      //   id +
      //   '&nameSpace=' +
      //   window.location.hostname +
      //   (window.location.port ? ':' + window.location.port : '') +
      //   '/das-api'
      // window.open(url, '_blank')
    },
    // 试推送 弹框 确认
    confirm() {
      if (!this.tryPushData.email) {
        this.$message({
          type: 'warning',
          message: '请填写试推送邮箱地址!'
        })
        return
      }
      if (this.tryPushCheckListId.length == 0) {
        this.$message({
          type: 'warning',
          message: '请选择要推送的模板'
        })
        return
      }
      this.dialogFormVisible = false
      this.$confirm(`您即将对${this.currentTask.topic}内容进行试推送,是否现在推送?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        cancelButtonClass: 'btn-custom-cancel',
        type: 'warning'
      })
        .then(() => {
          let params = {
            taskId: this.taskId,
            email: this.tryPushData.email,
            nameSpace: window.location.hostname + (window.location.port ? ':' + window.location.port : '') + '/das-api'
          }
          this.tryPushCheckListId.map((item) => {
            params.pushHistoryTemplateId = item
          })
          TaskTrySend(params).then((res) => {
            if (res && res.data && res.data.status === 0) {
              this.$message({
                type: 'success',
                message: '推送成功!'
              })
            } else {
              const { message = '' } = res.data
              this.$message.error(message)
              if (message.includes('请在配置管理配置模板信息')) {
                window.setTimeout(() => {
                  this.$router.push('/configure')
                }, 2000)
              }
            }
          })
        })
        .catch(() => { })
    },
    // 正式推送
    officialPush() {
      this.getTaskInfoData(this.taskId, 'officialPush')
    },
    // 选择学者领域
    chooseScholarMag() {
      // this.$router.push({
      //   path: '/scholarMag',
      //   query: {
      //     taskId: this.taskId
      //   }
      // })
      const url = this.$router.resolve({
        path: '/scholarMag',
        query: {
          taskId: this.taskId
        }
      }).href;
      window.open(url, '_blank');
    },
    // 内容配置
    toContentConfigure() {
      let routeData = this.$router.resolve({
        path: '/contentConfigure',
        query: {
          taskId: this.taskId
        }
      })
      window.open(routeData.href, '_blank')
    },
    // 获取推送任务模板默认配置
    getTemplate(type) {
      let params = {}
      if (type) {
        params = {
          historyId: this.taskId,
          isChoice: type
        }
      } else {
        params = {
          historyId: this.taskId
        }
      }
      GetTemplate(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          let { data } = res.data
          if (type) {
            this.tryPushCheckList = data
            this.tryPushCheckListId = []
            if (data.length > 0) {
              data.map((item, index) => {
                this.tryPushCheckListId.push(item.id)
              })
            }
          } else {
            this.historyTemplateList = data
          }
        }
      })
    },
    commitAnalysis() {
      let params = {
        magId: this.magId,
        historyId: this.taskId
      }
      ReferenceArticleAnalysis(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          this.$message.success('操作成功')
          this.analysisDialog = false
        }
      })
    },
    downloadAnalysis() {
      window.open(`${ExportAnalysisFile}?historyId=${this.taskId}`, '_self')
    }
  }
}
</script>
<style lang="scss">
.taskManagement {
  .list-btn {
    margin-bottom: 12px;
  }
}

.previewEmail-popper {
  .previewEmail-list {
    .previewEmail-list-item {
      margin: 6px 0;
      cursor: pointer;

      &:hover {
        color: #265cca;
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.taskManagement {
  .center_2 {
    position: relative;

    //新建任务按钮
    .new-btn {
      margin: 0 10px 10px 0;
      position: absolute;
      left: 30px;
    }

    // 内容类型筛选
    .task-type {
      display: inline-block;
      position: absolute;
      left: 11%;
    }

    .year-picker {
      width: 100%;
    }

    .btn-disabled {
      color: #ccc;
      cursor: no-drop;
    }

    //查看学者弹框
    .check-scholar-dialog {
      h2 {
        margin: 10px 0;
      }

      .num-btn {
        display: flex;
        justify-content: space-around;

        .left {
          text-align: left;

          .num {
            margin-bottom: 10px;
          }
        }
      }

      .right {
        .reflesh-author {
          text-align: right;
          color: #265cca;
          cursor: pointer;
          margin-left: 20px;
        }

        .btn-disabled {
          color: #ccc;
          cursor: no-drop;
        }
      }

      .time {
        text-align: left;
        margin: 20px 0 0 12px;
      }

      .scholar-analysis-btn {
        margin: 20px 0;
      }
    }

    .previewEmail {
      margin: 0 80px;
    }

    .tryPush-list {
      margin-top: 40px;
      display: flex;
      flex-wrap: wrap;

      .tryPush-list-item {
        margin: 4px 8px;
      }
    }
  }
}
</style>
