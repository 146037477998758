<template>
  <div class="sk-template-box">
    <module0 :item="mouldConfig.modlue0"></module0>
    <module1 :item="mouldConfig.modlue1" showType showTitle></module1>
    <module3 :item="mouldConfig.modlue3" :showAbstract="false" showCover showTopicTranslation showColumnTranslation showAuthorsTranslation showKeywordsTranslation></module3>
    <module6 :item="mouldConfig.modlue6" showAbout showType></module6>
    <module7 :item="mouldConfig.modlue7" showType></module7>
    <module8 :item="mouldConfig.modlue8"></module8>
  </div>
</template>

<script>
import module0 from './template/module0'
import module1 from './template/module1'
import module3 from './template/module3'
import module6 from './template/module6'
import module7 from './template/module7'
import module8 from './template/module8'

export default {
  props: {
    mouldConfig: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  components: {
    module0,
    module1,
    module3,
    module6,
    module7,
    module8
  },
  watch: {
    'mouldConfig.modlue4.showNumber'(value) {
      this.handleCom4Number(value)
    },
    'mouldConfig.modlue3.showNumber'(value) {
      this.handleCom3Number(value)
    }
  },
  data() {
    return {}
  },
  methods: {
    // 配置模版显示次数
    handleCom3Number(value) {
      if (value == '') {
        return false
      }
      if (value == -1) {
        this.mouldConfig.modlue5.isShow = false
        this.mouldConfig.modlue5.showNumber = ''
        this.mouldConfig.modlue5.disabled = true
        this.mouldConfig.modlue4.isShow = false
        this.mouldConfig.modlue4.showNumber = ''
        this.mouldConfig.modlue4.disabled = true
      } else {
        this.mouldConfig.modlue4.isShow = true
        this.mouldConfig.modlue4.disabled = false
        this.mouldConfig.modlue5.isShow = true
        this.mouldConfig.modlue5.disabled = false
      }
    },
    handleCom4Number(value) {
      if (value == '') {
        return false
      }
      if (value == -1) {
        this.mouldConfig.modlue5.isShow = false
        this.mouldConfig.modlue5.showNumber = ''
        this.mouldConfig.modlue5.disabled = true
      } else {
        this.mouldConfig.modlue5.isShow = true
        this.mouldConfig.modlue5.disabled = false
      }
    }
  }
}
</script>
