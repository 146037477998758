<template>
  <el-dialog title="导入" align="left" :close-on-click-modal="false" :visible.sync="importDialogVisible" width="560px" :show-close="true" custom-class="import-template">
    <el-form ref="importForm" :model="formtData" :rules="uploadRule">
      <el-form-item prop="fileValue" label="导入文件：" align="left">
        <el-input disabled type="text" v-model="inputText" placeholder="选择本地文件" class="import-template-input"></el-input>
        <el-upload
          ref="importFile"
          class="import-template-upload"
          :on-change="handleUploadChange"
          :show-file-list="uploadObject?.showFileList"
          :file-list="fileList"
          :action="uploadObject?.action"
          :data="uploadParams"
          :accept="uploadObject?.accept"
          :auto-upload="uploadObject?.autoUpload"
          :on-success="handleUploadSuccess"
        >
          <el-button class="import-template-upload_btn" type="primary">{{ uploadObject?.text }}</el-button>
        </el-upload>
      </el-form-item>
      <el-form-item label="说明：" label-width="95px" align="left">
        <ul>
          <li>1.如果文件数据量过大,处理时间会比较长,建议在10000条以内的数据</li>
          <li>2.仅支持.xls、.xlsx格式的文件；请严格按照模版文件中的格式进行数据填充，否则系统无法正常识别</li>
        </ul>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer" align="center">
      <el-button type="primary" @click="confirmImport">确 定</el-button>
      <el-button @click="cancel">取 消</el-button>
    </div>
  </el-dialog>
</template>
<script>
import { ArticleImport } from '@/api/selfPush'
export default {
  name: 'ImportTemplat',
  props: {
    uploadObject: {
      type: Object,
      default: () => {
        return {
          accept: '.xlsx,.xls',
          action: ArticleImport,
          text: '选择文件',
          autoUpload: false,
          showFileList: false
        }
      }
    },
    networkId: {
      type: [String, Number],
      default: ''
    }
  },
  computed: {
    uploadParams() {
      return {
        networkId: this.networkId
      }
    }
  },
  data() {
    return {
      importDialogVisible: false,
      inputText: '',
      formtData: {
        fileValue: ''
      },
      uploadRule: {
        fileValue: [{ required: true, trigger: 'blur', message: '请上传文件' }]
      },
      fileList: []
    }
  },
  methods: {
    // 导入确认按钮
    confirmImport() {
      this.$refs.importForm.validate(valid => {
        if (valid) {
          this.$refs.importFile.submit()
          this.importDialogVisible = false
        }
      })
    },
    open() {
      this.importDialogVisible = true
      this.fileList = []
    },
    // 取消
    cancel() {
      this.importDialogVisible = false
      this.init()
    },
    init() {
      this.inputText = ''
      this.formtData = {}
      this.fileList = []
    },
    // 上传状态改变
    handleUploadChange(file) {
      const { status } = file
      if (status === 'success') {
        return
      }
      const { raw } = file
      const { type = '' } = raw
      if (type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        this.$message.warning('上传文件格式错误')
        return false
      }
      this.$refs.importFile.clearFiles()
      this.fileList = [file]
      const { name = '' } = file
      this.inputText = name
      this.$set(this.formtData, 'fileValue', name)
      this.$refs.importForm.clearValidate()
    },
    // 上传成功
    handleUploadSuccess(res, file) {
      this.init()
      if (res?.status === 0) {
        this.$emit('getList')
      } else {
        const { message = '' } = res
        this.$message.error(message)
      }
    }
  }
}
</script>
<style lang="scss">
.import-template {
  &-text {
    margin-top: 20px;
    display: inline-block;
  }
  &-input {
    display: inline-block;
    width: 70%;
  }
  &-upload {
    display: inline-block;
  }
  &-upload_btn {
    position: absolute;
    top: 2px;
    right: 5px;
    height: 28px;
    line-height: 8px;
    width: auto;
    background-color: #91949d;
    border-color: unset;
    &:hover {
      background-color: #9ea1ab;
      border: unset;
    }
  }
}
</style>
