<template>
  <div class="w center_all aggregation">
    <div class="center_2">
      <ul class="z_tabs">
        <li :class="tabsShow === 0 ? 'active' : 'noBack'" @click="tabsShow = 0"><span>优先出版文章</span></li>
        <li :class="tabsShow === 1 ? 'active poR' : 'poR noBack'" @click="tabsShow = 1"><span>整期发表期刊</span></li>
        <li :class="tabsShow === 2 ? 'active poR1' : 'poR1 noBack'" @click="tabsShow = 2"><span>高被引文章</span></li>
      </ul>
      <el-tabs v-model="activeName" type="card" class="tabs1">
        <div v-if="tabsShow === 0" class="tabItem">
          <!--          <ul class="crumbs clearfix">-->
          <!--            <li>内容聚合</li>-->
          <!--            <li>-></li>-->
          <!--            <li>优先出版文章</li>-->
          <!--          </ul>-->
          <div class="sort clearfix">
            <span class="fl">共计 <span v-text="firstPageObj.total || 0" /> 条记录</span>
            <span class="fl">排序： </span>
            <el-checkbox-group v-model="firstArticleRadio" class="fl" @change="sorTable">
              <el-checkbox-button size="small" label="firstPubTime">发表时间</el-checkbox-button>
            </el-checkbox-group>
            <!--       切换表格or摘要     -->
            <el-radio-group v-model="radio4" class="fr btn_listTable" size="small" @change="listTable">
              <el-radio-button label="2" class="btn_list" />
              <el-radio-button label="1" class="btn_table" />
            </el-radio-group>
            <div class="btn3 fr clearfix">
              <span class="tj_year fl">统计时间:</span>
              <el-select v-model="firstChooseTime" placeholder="请选择" class="fl" style="width:120px;top: 3px;">
                <el-option v-for="item in timeOptions" :key="item.value" :label="item.label" :value="item.value" />
              </el-select>
            </div>
            <!-- </div> -->
          </div>
          <div v-if="tabList">
            <div class="conter_3">
              <SimpleTable
                @onClickTitle="handleOpenUrl"
                :tableData="firstArticleList"
                :columnData="firstArticleColumn"
                :initObj="firstPageObj"
                @onChangePageData="
                  pageObj => {
                    getFirstArticle(pageObj)
                  }
                "
              />
            </div>
          </div>
          <div v-else>
            <ul class="conter_list">
              <li v-for="(item, index) in firstArticleList" :key="item.id">
                <div
                  @click="
                    () => {
                      handleOpenUrl(item)
                    }
                  "
                  class="conter_list_title"
                >
                  <span>{{ (firstPageObj.page - 1) * firstPageObj.size + index + 1 }}.</span>
                  <a href="#" v-html="item.title"></a>
                </div>
                <ul class="conter_list_more clearfix">
                  <li><span>作者：</span><span v-html="item.authors && item.authors !== '' ? item.authors : '--'"></span></li>
                  <li><span>期刊：</span><span v-html="item.magCn && item.magCn !== '' ? item.magCn : '--'"></span></li>
                  <!--                  <li><span>刊期：</span><span>2020年第2期</span></li>-->
                </ul>
                <div class="conter_list_abstract">
                  {{ item.abstractContent }}
                </div>
                <ul class="conter_list_more clearfix">
                  <li><span>关键词：</span><span v-html="item.keywords && item.keywords !== '' ? item.keywords : '--'"></span></li>
                  <li><span>发表时间：</span><span v-html="item.firstPubTime && item.firstPubTime !== '' ? item.firstPubTime : '--'"></span></li>
                </ul>
              </li>
            </ul>
            <Pagination
              :initObj="firstPageObj"
              @onChangePageData="
                pageObj => {
                  getFirstArticle(pageObj)
                }
              "
            />
          </div>
        </div>
        <div v-if="tabsShow === 1" class="tabItem">
          <!--          <ul class="crumbs clearfix">-->
          <!--            <li>内容聚合</li>-->
          <!--            <li>-></li>-->
          <!--            <li>整期发表期刊</li>-->
          <!--          </ul>-->
          <div class="sort clearfix">
            <span class="fl">共计 <span v-text="issuePageObj.total || 0" /> 条记录</span>
            <span class="fl">排序： </span>
            <el-checkbox-group v-model="issueRadio" class="fl">
              <el-checkbox-button size="small" label="firstPubTime">发表时间</el-checkbox-button>
            </el-checkbox-group>
            <div class="btn3 fr clearfix">
              <span class="tj_year fl">统计时间:</span>
              <el-select v-model="issueChooseTime" placeholder="请选择" class="fl" style="width:120px;top: 3px;">
                <el-option v-for="item in timeOptions" :key="item.value" :label="item.label" :value="item.value" />
              </el-select>
            </div>
          </div>
          <div>
            <div class="conter_3">
              <el-table ref="tableData2" :data="issuesTableData" class="tableBtn" stripe style="width: 100%">
                <el-table-column type="expand" width="1px">
                  <template slot-scope="props">
                    <div class="r_b">
                      <el-table :data="props.row.data" border class="r_table" style="width: 100%">
                        <el-table-column type="index" label="序号" align="center" width="90">
                          <template slot-scope="scope">
                            <span>{{ (issueArticlePageObj.page - 1) * issueArticlePageObj.size + scope.$index + 1 }}</span>
                          </template>
                        </el-table-column>
                        <el-table-column prop="title" show-overflow-tooltip label="论文标题" width="400px">
                          <template slot-scope="scope">
                            <span
                              class="article-title"
                              @click="
                                () => {
                                  handleClickTitle(scope.row)
                                }
                              "
                              >{{ scope.row.title && scope.row.title !== '' ? scope.row.title : '--' }}</span
                            >
                          </template>
                        </el-table-column>
                        <el-table-column :formatter="handleColumnFormatter" prop="authors" show-overflow-tooltip label="作者" />
                        <el-table-column :formatter="handleColumnFormatter" prop="institutions" show-overflow-tooltip label="机构" />
                        <!--                        <el-table-column prop="publishDate" width="220px" align="center" label="发表时间" />-->
                      </el-table>
                      <Pagination
                        :initObj="issueArticlePageObj"
                        no-background
                        @onChangePageData="
                          pageObj => {
                            getIssueArticleData(pageObj)
                          }
                        "
                      />
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="序号" width="90px" align="center" type="index">
                  <template slot-scope="scope">
                    <span>{{ (issuePageObj.page - 1) * issuePageObj.size + scope.$index + 1 }}</span>
                  </template>
                </el-table-column>
                <el-table-column :formatter="handleColumnFormatter" label="刊物名称" width="400px" prop="magName" />
                <el-table-column :formatter="handleColumnFormatter" label="年份" prop="year" />
                <el-table-column label="刊期" width="100px" prop="issue">
                  <template slot-scope="props">
                    <div
                      class="curs"
                      @click="
                        () => {
                          qiehuna(props.row)
                        }
                      "
                    >
                      {{ props.row.issue && props.row.issue !== '' ? props.row.issue : '--' }}
                      <i :style="{ transform: props.row.id === expandedRow.id ? 'rotateZ(0)' : '' }" class="el-icon-caret-bottom" />
                    </div>
                  </template>
                </el-table-column>
                <el-table-column :formatter="handleColumnFormatter" label="出版时间" prop="publishDate" />
              </el-table>
              <Pagination
                :initObj="issuePageObj"
                @onChangePageData="
                  pageObj => {
                    getIssuesData(pageObj)
                  }
                "
              />
            </div>
          </div>
        </div>
        <div v-if="tabsShow === 2" class="tabItem">
          <!--          <ul class="crumbs clearfix">-->
          <!--            <li>内容聚合</li>-->
          <!--            <li>-></li>-->
          <!--            <li>高被引文章</li>-->
          <!--          </ul>-->
          <!--          <div class="sort clearfix">-->
          <!--            <span class="fl">共计 600 条记录</span>-->
          <!--            <span class="fl">排序： </span>-->
          <!--            <el-checkbox-group v-model="radio2" class="fl" @change="sorTable2">-->
          <!--              <el-checkbox-button size="small" label="发表时间">发表时间</el-checkbox-button>-->
          <!--              <el-checkbox-button size="small" label="被引量">被引量</el-checkbox-button>-->
          <!--            </el-checkbox-group>-->
          <!--            <div class="btn3 fr clearfix">-->
          <!--              <span class="tj_year fl">统计年份:</span>-->
          <!--              <el-select v-model="value2" placeholder="请选择" class="fl" style="width:120px;top: 3px;">-->
          <!--                <el-option v-for="item in options2" :key="item.value" :label="item.label" :value="item.value" />-->
          <!--              </el-select>-->
          <!--            </div>-->
          <!--          </div>-->
          <div>
            <div class="articleSearch">
              <el-select v-model="articleTypeValue" placeholder="请选择" style="width:100px;top:-4px;">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value" />
              </el-select>
              <el-button
                type="text"
                icon="el-icon-xz"
                @click="
                  () => {
                    handleDownload('article')
                  }
                "
                >下载</el-button
              >
            </div>
            <SimpleTable
              ref="articleTable"
              :tableData="articleTableData"
              :columnData="articleColumn"
              :initObj="articlePageObj"
              @onChangePageData="
                pageObj => {
                  getArticleData(pageObj)
                }
              "
              @onChangeSort="handleChangeArticleSort"
              @onClickTitle="handleArticleDetails"
            />
          </div>
        </div>
      </el-tabs>
    </div>
  </div>
</template>
<script>
import { GetFirstArticle, GetIssues, GetIssueArticles } from '@/api/aggregation'
import { GetArticleInfo } from '@/api/article'
import { GetArticle, exportArticle } from '@/api/analysis'
import SimpleTable from '@/components/SimpleTable'
import Pagination from '@/components/Pagination'
import { mapGetters } from 'vuex'
export default {
  name: 'Aggregation',
  components: {
    SimpleTable,
    Pagination
  },
  mounted() {
    if (this.magId) {
      this.getFirstArticle()
      this.getArticleData()
      this.getIssuesData()
    }
  },
  computed: {
    ...mapGetters({
      magId: 'magId'
    })
  },
  watch: {
    magId() {
      // if (this.activeName === 'first') {
      //   this.getFirstArticle()
      //   return
      // }
      // if (this.activeName === 'second') {
      //   this.getIssuesData()
      //   return
      // }
      this.getFirstArticle()
      this.getArticleData()
      this.getIssuesData()
    },
    activeName(nv) {
      if (nv === 'first') {
        this.getFirstArticle()
        return
      }
      if (nv === 'second') {
        this.getIssuesData()
        return
      }
      this.getFirstArticle()
      this.getArticleData()
      this.getIssuesData()
    },
    firstChooseTime() {
      this.getFirstArticle()
    },
    issueChooseTime() {
      this.getIssuesData()
    },
    firstArticleRadio() {
      this.getFirstArticle()
    },
    issueRadio() {
      this.getIssuesData()
    },
    articleTypeValue() {
      this.getArticleData()
      this.$refs.articleTable.clearSort()
    }
  },
  data() {
    return {
      firstArticleList: [], // 优先出版文章列表
      firstPageObj: {
        page: 1,
        size: 10
      }, // 优先出版的分页数据
      firstArticleColumn: [
        {
          title: '论文标题',
          prop: 'title',
          special: true,
          width: '300px',
          notSortable: true
        },
        {
          title: '刊物名称',
          prop: 'magCn',
          notSortable: true
        },
        {
          title: '作者',
          prop: 'authors',
          notSortable: true
        },
        {
          title: '作者机构',
          prop: 'institutions',
          notSortable: true
        },
        {
          title: '发表时间',
          prop: 'firstPubTime',
          notSortable: true
        }
      ], // 优先出版文章的column
      firstArticleRadio: [], // 优先出版的按照发表时间排序
      // 论文引证统计
      articlePageObj: {
        page: 1,
        size: 10,
        sort: 'totalCount,desc'
      },
      articleTableData: [],
      articleTypeValue: 'mag',
      // 发文统计和引证统计的option选项
      options: [
        {
          value: 'mag',
          label: '本刊'
        },
        {
          value: 'domain',
          label: '本领域'
        }
      ],
      articleColumn: [
        {
          title: '论文标题',
          prop: 'articleName',
          special: true,
          width: '300px',
          notSortable: true
        },
        {
          title: '刊物名称',
          prop: 'magName',
          notSortable: true
        },
        {
          title: '作者',
          prop: 'author',
          notSortable: true
        },
        {
          title: '机构',
          prop: 'institution',
          notSortable: true
        },
        {
          title: '年份',
          prop: 'year'
        },
        {
          title: '刊期',
          prop: 'issue',
          notSortable: true
        },
        {
          title: '引证次数',
          prop: 'totalCount'
        }
      ], // 论文引证统计 表格header 内容
      activeName: 'first',
      firstChooseTime: 7,
      issueChooseTime: 7,
      timeOptions: [
        {
          value: 7,
          label: '最近一周'
        },
        {
          value: 30,
          label: '最近一个月'
        },
        {
          value: 92,
          label: '最近三个月'
        },
        {
          value: 183,
          label: '最近半年'
        }
      ],
      value1: '最近一周',
      options2: [
        {
          value: 1,
          label: '本刊'
        },
        {
          value: 2,
          label: '本领域'
        }
      ],
      value2: '最近三个月',
      radio: ['发表时间'],
      radio2: ['发表时间'], // 默认选择
      radio4: '2', // 选择列表还是表格  1为表格
      tabList: false, // 表格还是列表 true 为表格 ，false为列表
      // 整期期刊的列表
      issuesTableData: [],
      issuePageObj: {
        page: 1,
        size: 10
      },
      issueArticlePageObj: {
        page: 1,
        size: 10
      },
      issueRadio: [], // 整期发表文章的按照发表时间排序
      chooseRowKeys: [], // 展开的行key值形成的列表
      expandedRow: {}, // 当前展开行的数据
      chooseYear: '',
      chooseMagNumber: '',
      chooseMagId: '',
      tabsShow: 0
    }
  },
  methods: {
    // 列表中的
    handleColumnFormatter(row, column, cellValue) {
      if (cellValue && cellValue !== '') {
        if (column.label === '出版时间') {
          return cellValue.split(' ')[0]
        }
        return cellValue
      }
      return '--'
    },
    // 点击整期下文章列表的标题跳转官网或知网的详情页
    handleClickTitle(chooseItem = {}) {
      const { id = '' } = chooseItem
      if (id && id !== '') {
        const params = {
          id
        }
        GetArticleInfo(params).then(res => {
          if (res && res.data && res.data.status === 0) {
            const { data = {} } = res.data
            const { officialWebsiteUrl = '', url = '' } = data
            if (officialWebsiteUrl && officialWebsiteUrl !== '') {
              window.open(officialWebsiteUrl)
            } else if (url && url !== '') {
              window.open(url)
            }
          } else {
            const { message = '' } = res.data
            this.$message.error(message)
          }
        })
      }
    },
    // 获取整期下的文章列表
    getIssueArticleData(pageParams = {}) {
      const { page = 1, size = 10 } = pageParams
      const params = {
        magId: this.chooseMagId,
        year: this.chooseYear,
        magNumber: this.chooseMagNumber,
        page: page - 1,
        size
      }
      GetIssueArticles(params).then(res => {
        if (res && res.data && res.data.status === 0) {
          const { content = [], totalElements = 0 } = res.data.data
          this.issueArticlePageObj = {
            ...this.issueArticlePageObj,
            page,
            size,
            total: totalElements
          }
          this.expandedRow.data = content
          this.$refs.tableData2.toggleRowExpansion(this.expandedRow, true)
        } else {
          const { message = '' } = res.data
          this.$message.error(message)
        }
      })
    },
    // 点击展开行
    qiehuna(row) {
      const { id, year, issue, magId } = row
      if (this.chooseRowKeys.includes(id)) {
        this.$refs.tableData2.toggleRowExpansion(row, false)
        this.chooseRowKeys = []
        this.expandedRow.data = []
        this.expandedRow = {}
      } else {
        if (this.chooseRowKeys.length > 0) {
          this.$refs.tableData2.toggleRowExpansion(this.expandedRow, false)
          this.expandedRow.data = []
        }
        this.chooseRowKeys = [id]
        this.chooseYear = year
        this.chooseMagNumber = issue
        this.chooseMagId = magId
        this.expandedRow = row
        this.getIssueArticleData()
      }
    },
    // 获取整期发表期刊的数据
    getIssuesData(pageParams = {}) {
      const { page = 1, size = 10 } = pageParams
      const { startTime = '', endTime = '' } = this.getTimePeriod(this.issueChooseTime)
      const params = {
        id: this.magId,
        page: page - 1,
        size,
        startTime,
        endTime,
        sort: this.issueRadio.length > 0 ? 'publishDate,DESC' : ''
      }
      GetIssues(params).then(res => {
        if (res && res.data && res.data.status === 0) {
          const { data } = res.data
          const { content = [], totalElements = 0 } = data
          this.issuePageObj = {
            ...this.issuePageObj,
            page,
            size,
            total: totalElements
          }
          this.issuesTableData = content
          this.expandedRow.data = []
          this.expandedRow = {}
        } else {
          const { message } = res.data
          this.$message.error(message)
        }
      })
    },
    // 点击进入文章详情
    handleArticleDetails(chooseItem) {
      const { articleId = '' } = chooseItem
      if (articleId && articleId !== '') {
        this.$router.push({ path: '/articleDetails', query: { searchId: articleId } })
      }
    },
    // 跳转链接
    handleOpenUrl(chooseItem) {
      const { url = '', officialWebsiteUrl = '' } = chooseItem
      if (officialWebsiteUrl && officialWebsiteUrl !== '') {
        window.open(officialWebsiteUrl)
      } else if (url && url !== '') {
        window.open(url)
      }
    },
    // 处理所有下载的函数
    handleDownload(downloadType = '') {
      if (downloadType === 'article') {
        window.open(
          `${exportArticle}?magId=${this.magId}&page=${this.articlePageObj.page - 1}&size=${this.articlePageObj.size}&sort=${this.articlePageObj.sort || ''}&type=${this.articleTypeValue}`,
          '_self'
        )
      }
    },
    handleChangeArticleSort(prop, order) {
      // 聚类引用趋势列表排序改变
      let sortValue = ''
      if (order && order !== '') {
        if (order === 'ascending') {
          sortValue = prop + ',asc'
        } else {
          sortValue = prop + ',desc'
        }
      }
      this.articlePageObj.sort = sortValue
      this.getArticleData()
    },
    // 高被引统计数据请求
    getArticleData(pageParams = {}) {
      const { page = 1, size = 10 } = pageParams
      const params = {
        magId: this.magId,
        page: page - 1,
        size,
        type: this.articleTypeValue,
        sort: this.articlePageObj.sort
      }
      GetArticle(params).then(res => {
        if (res && res.data && res.data.status === 0) {
          const { data } = res.data
          const { content = [], totalElements = 0 } = data
          this.articlePageObj = {
            ...this.articlePageObj,
            page,
            size,
            total: totalElements
          }
          this.articleTableData = content
        } else {
          const { message } = res.data
          this.$message.error(message)
        }
      })
    },
    // 处理date为字符串
    changeDateToString(date = new Date()) {
      const seperator = '-'
      const year = date.getFullYear()
      let month = date.getMonth() + 1
      let strDate = date.getDate()
      if (month >= 1 && month <= 9) {
        month = '0' + month
      }
      if (strDate >= 0 && strDate <= 9) {
        strDate = '0' + strDate
      }
      const currentDate = year + seperator + month + seperator + strDate
      return currentDate
    },
    // 开始和结束时间字符串的生成
    getTimePeriod(dayNum = 0) {
      const nowDate = new Date()
      const date = new Date(nowDate)
      date.setDate(date.getDate() - dayNum)
      const startTime = this.changeDateToString(date)
      const endTime = this.changeDateToString(nowDate)
      return {
        startTime,
        endTime
      }
    },
    // 查询优先出版文章的数据
    getFirstArticle(pageParams = {}) {
      const { page = 1, size = 10 } = pageParams
      const { startTime = '', endTime = '' } = this.getTimePeriod(this.firstChooseTime)
      let params = {}
      if (this.firstArticleRadio.length > 0) {
        params = {
          id: this.magId,
          page: page - 1,
          size,
          startTime: startTime,
          endTime: endTime,
          sort: 'firstPubTime,DESC'
        }
      } else {
        params = {
          id: this.magId,
          page: page - 1,
          size,
          startTime: startTime,
          endTime: endTime
        }
      }
      GetFirstArticle(params).then(res => {
        if (res && res.data && res.data.status === 0) {
          const { data } = res.data
          const { content = [], totalElements = 0 } = data
          this.firstPageObj = {
            page,
            size,
            total: totalElements
          }
          this.firstArticleList = content
        }
      })
    },
    // 排序 选择 优先出版文章
    sorTable(val) {
      console.log(val, this.radio)
    },
    // 表格 列表 切换
    listTable(val) {
      if (val === '1') {
        this.tabList = true
      } else {
        this.tabList = false
      }
    }
  }
}
</script>
<style scoped>
.conte_1 {
  background-color: #f6f7fc;
  position: relative;
  margin-bottom: 20px;
}
.conte_tit_1 {
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 200;
}
.tj_year {
  height: 32px;
  line-height: 12px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 14px;
  padding: 9px 10px 9px 0;
  color: #999;
}
.conte_table {
  width: 50%;
  position: absolute;
  top: 20px;
  right: 20px;
}
.abstract {
  text-align: left;
}
.title_z {
  font-size: 20px;
  color: #333333;
  line-height: 30px;
  margin-top: 10px;
}
.title_e {
  font: 14px;
  color: #999;
  line-height: 20px;
  margin-bottom: 10px;
}
.abstract_t {
  font-size: 14px;
  line-height: 24px;
  color: #666;
}
.more {
  margin-top: 10px;
  margin-bottom: 10px;
}
.more li {
  line-height: 24px;
  font-size: 14px;
  color: #999;
}
.more li span {
  color: #666;
}
.sort {
  text-align: left;
  margin-bottom: 7px;
}
.sort > span {
  height: 32px;
  line-height: 14px;
  box-sizing: border-box;
  font-size: 14px;
  padding: 9px 10px 9px 0;
  color: #999;
}
.conter_list {
  text-align: left;
}
.conter_list > li {
  /*border-top: 1px solid #ccc;*/
  border-bottom: 1px solid #ebeef5;
  padding: 20px 0;
}
.conter_list_title {
  font-size: 16px;
  color: #333;
  line-height: 24px;
}
.conter_list_more {
  color: #999;
  font-size: 14px;
  line-height: 24px;
}
.conter_list_more li {
  float: left;
  margin-right: 14px;
}
.conter_list_abstract {
  margin-top: 10px;
  color: #666;
  line-height: 24px;
  font-size: 14px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.conter_list_keywords {
  margin-top: 10px;
  font-size: 14px;
  color: #999;
  line-height: 18px;
}
.postion2 {
  width: 200px;
  float: right;
  position: relative;
}
.btn {
  position: absolute;
  top: 0;
  right: 0;
}
.curs {
  cursor: pointer;
  position: relative;
}
.curs .el-icon-caret-bottom {
  position: absolute;
  top: 5px;
  /*left: 10px;*/
  transform-origin: center;
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
  -webkit-transform: rotateZ(180deg);
  transform: rotateZ(180deg);
}
.crumbs {
  position: absolute;
  top: -20px;
  left: 0;
}
.btn_listTable {
  margin-left: 12px;
}
.articleSearch {
  margin-top: 0px;
  display: flex;
  justify-content: flex-end;
}
.article-title {
  cursor: pointer;
}
.z_tabs {
  position: absolute;
  top: 39px;
  left: -42px;
}
.z_tabs li:before {
  content: '';
  height: 107px;
  width: 100%;
  position: absolute;
  background: #546ee0;
  left: 0;
  border-top-left-radius: 8px;
}
.z_tabs li.active:before {
  background: #fff;
}
.z_tabs li span {
  position: absolute;
  top: 28px;
  left: 50%;
  display: inline-block;
  transform: translateX(-50%);
  font-weight: bold;
  color: #fff;
}
.z_tabs li {
  color: #fff;
  box-sizing: border-box;
  font-size: 16px;
  padding: 14px 13px 0;
  line-height: 18px;
  width: 42px;
  height: 180px;
  background: url('../../../public/imgs/tab03.png') center bottom no-repeat;
  cursor: pointer;
  position: relative;
  z-index: 1;
}
.z_tabs li:last-child {
  z-index: 1;
}
.poR {
  top: -40px;
}
.poR1 {
  top: -80px;
}
.z_tabs li.active {
  color: #fff;
  background-image: url('../../../public/imgs/tab04.png');
  z-index: 4;
}
.z_tabs li.active span {
  color: #265cca;
}
.noBack {
  background: url('../../../public/imgs/tab03.png') center bottom no-repeat;
}
</style>
